<template>
  <ul
    class="email-media-list"
  >
    <b-media
      v-for="(row) in listviewData"
      :key="row.id"
      tag="li"
      no-body
      @click.stop.prevent="openModalActionByRow(row)"
    >
      <b-media-aside class="media-left mr-50">
        <!-- Fotografia -->
        <b-avatar
          class="avatar"
          size="40"
          variant="primary"
          :src="row.photo"
        />

        <!-- Inatividade / checkbox -->
        <div class="user-action">
          <b-form-checkbox
            :checked="filterSelected.leadsID.value.includes(row.id)"
            :disabled="checkButtonSelectAllIsDisabled"
            @change="toggleSelectedLead(row.id)"
            @click.native.stop
          />
          <div class="email-favorite">
            <span
              v-b-tooltip
              :title="row.inatividade_title"
              class="mx-50 bullet bullet-sm"
              :class="colorInatividade(row.inatividade)"
            />
          </div>
        </div>
      </b-media-aside>
      <b-media-body>
        <div class="mail-details">
          <div class="mail-items">
            <h5
              v-if="row.name"
              class="mb-25"
              :class="isHighlightsRGPD(row)"
            >
              {{ row.name }}
            </h5>
            <a
              v-if="row.email"
              :class="[isHighlightsRGPD(row), {'txt-grey-link': true }]"
              :href="`mailto:${row.email}`"
            >
              <feather-icon
                icon="MailIcon"
              />
              {{ row.email }}
            </a>
            <a
              v-if="row.phone"
              :href="`tel:${row.phone}`"
              :class="[{'d-none d-sm-inline': true, 'px-2': ((row.email) ? true : false), 'txt-grey-link' : true}, isHighlightsRGPD(row)]"
            >
              <feather-icon
                icon="PhoneCallIcon"
              />
              {{ row.phone }}
            </a>
            <a
              v-if="row.phone"
              :href="`tel:${row.phone}`"
              :class="[{'d-inline d-sm-none': true, 'txt-grey-link': true}, isHighlightsRGPD(row)]"
            >
              <feather-icon
                icon="PhoneCallIcon"
              />
              {{ row.phone }}
            </a>
          </div>
        </div>

        <!-- Tipo de cliente -->
        <div
          v-if="row.txt_tipos_cliente"
          class="mail-message"
        >
          <p class="mb-0">
            {{ row.txt_tipos_cliente }}
          </p>
        </div>

        <!-- Responsavel -->
        <div
          v-if="(row.users_rsp_lead !== undefined) && (row.users_rsp_lead.length > 0)"
          class="mail-message"
        >
          <p class="mb-0">
            <b>{{ $t('Responsável') }}:&nbsp;</b>
            {{ txt_list_responsavel(row.users_rsp_lead) }}
          </p>
        </div>

        <!-- Itens de alerta / Marcadores -->
        <div
          v-if="(listAlertIcons(row).length > 0) || (('bookmarks' in row) && (row.bookmarks.length > 0))"
          class="mail-message"
        >
          <p class="mb-0 mt-1">
            <feather-icon
              v-for="(alert, index) in listAlertIcons(row)"
              :key="`alert-${index}`"
              v-b-tooltip
              :icon="alert.icon"
              :title="alert.txt"
              :class="alert.class"
              class="mr-1"
              size="18"
            />
            <feather-icon
              v-for="bookmark in row.bookmarks"
              :key="bookmark.sw075s01"
              v-b-tooltip
              icon="BookmarkIcon"
              size="18"
              class="mr-75"
              :title="`${bookmark.sw075s03}`"
              :style="{color: bookmark.sw075s04}"
            />
          </p>
        </div>
      </b-media-body>
    </b-media>
  </ul>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BAvatar, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormCheckbox,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    toggleSelectedLead: {
      type: Function,
      required: true,
    },
    openModalActionByRow: {
      type: Function,
      required: true,
    },
    checkButtonSelectAllIsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_leads', ['listviewData', 'filterSelected']),
  },
  methods: {
    colorInatividade(days) {
      let color = 'bullet-danger'
      if (days <= 30) {
        color = 'bullet-success'
      } else if ((days > 30) && (days <= 60)) {
        color = 'bullet-warming'
      }
      return color
    },
    txt_list_responsavel(users) {
      let txtNamesRsp = ''

      if (users.length > 0) {
        users.forEach(name => {
          if (name) {
            txtNamesRsp += ((txtNamesRsp !== '') ? ', ' : '')
            txtNamesRsp += name
          }
        })
      }
      return txtNamesRsp
    },
    listAlertIcons(row) {
      const aIconsAlert = []

      // Angariado
      if (row.angariado !== undefined) {
        if (row.angariado === 1) {
          aIconsAlert.push({
            icon: 'HomeIcon',
            class: '',
            txt: this.$t('Angariado'),
          })
        }
      }

      // Consentimento
      if (row.consentimento !== undefined) {
        if (row.consentimento.id === 1) {
          aIconsAlert.push({
            icon: 'AlertCircleIcon',
            class: 'text-warning',
            txt: row.consentimento.txt,
          })
        } else if (row.consentimento.id === 2) {
          aIconsAlert.push({
            icon: 'CheckCircleIcon',
            class: 'text-info',
            txt: row.consentimento.txt,
          })
        } else if (row.consentimento.id === 3) {
          aIconsAlert.push({
            icon: 'XCircleIcon',
            class: 'text-danger',
            txt: row.consentimento.txt,
          })
        }
      }

      // Alertas gerais
      if ((row.alerts !== undefined) && (row.alerts.length > 0)) {
        row.alerts.forEach(alert => {
          if (alert.action === 'lead_send_to') {
            aIconsAlert.push({
              icon: 'SendIcon',
              class: 'text-danger',
              txt: alert.title,
            })
          }

          if (alert.action === 'reference_send_to') {
            aIconsAlert.push({
              icon: 'PaperclipIcon',
              class: 'text-danger',
              txt: alert.title,
            })
          }

          if (alert.action === 'total_shares_send') {
            aIconsAlert.push({
              icon: 'SendIcon',
              class: 'text-warming',
              txt: alert.title,
            })
          }

          if (alert.action === 'total_references_send') {
            aIconsAlert.push({
              icon: 'PaperclipIcon',
              class: 'text-warming',
              txt: alert.title,
            })
          }

          if (alert.action === 'total_references_recrut_send') {
            aIconsAlert.push({
              icon: 'Share2Icon',
              class: 'text-warming',
              txt: alert.title,
            })
          }

          if (alert.action === 'txt_familiar') {
            aIconsAlert.push({
              icon: 'UsersIcon',
              class: '',
              txt: alert.title,
            })
          }

          if (alert.action === 'lead_create_campaign') {
            aIconsAlert.push({
              icon: 'CrosshairIcon',
              class: '',
              txt: alert.title,
            })
          }

          if (alert.action === 'email_invalid') {
            aIconsAlert.push({
              icon: 'MailIcon',
              class: 'text-danger',
              txt: alert.title,
            })
          }

          if (alert.action === 'email_valid') {
            aIconsAlert.push({
              icon: 'MailIcon',
              class: 'text-success',
              txt: alert.title,
            })
          }

          if (alert.action === 'import_write') {
            aIconsAlert.push({
              icon: 'PenToolIcon',
              class: 'text-warning',
              txt: alert.title,
            })
          }

          if (alert.action === 'import_call') {
            aIconsAlert.push({
              icon: 'PhoneIncomingIcon',
              class: 'text-warning',
              txt: alert.title,
            })
          }
        })
      }
      return aIconsAlert
    },
    isHighlightsRGPD(row) {
      return ((row.rgpd === '1') ? 'text-danger' : '')
    },
  },
}
</script>

<style lang="scss" scoped>
.txt-grey-link{
  color: $text-muted;
}
</style>
