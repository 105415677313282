import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import btoa from 'btoa'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    filterSelected: state => ({
      tiposClienteLead: state.filterSelectedTiposClienteLead,
      tiposClienteContacto: state.filterSelectedTiposClienteContacto,
      bookmarksByUser: state.filterSelectedBookmarksByUser,
      classificacaoLead: state.filterSelectedClassificacaoLead,
      estadosLeadComprador: state.filterSelectedEstadosLeadComprador,
      estadosLeadVendedor: state.filterSelectedEstadosLeadVendedor,
      consentimento: state.filterSelectedConsentimento,
      responsableUser: state.filterSelectedResponsableUser,
      showStatusComprador: state.filterSelectedShowStatusComprador,
      showStatusVendedor: state.filterSelectedShowStatusVendedor,
      bookmarksCombinationByUser: state.filterSelectedBookmarksCombinationByUser,
      leadsID: state.filterSelectedLeadsID,
      totalFields: state.filterSelectedTotalFields,
      rgpdSituacoesPendentes: state.filterSelectedRGPDSituacoesPendentes,
      partilhados: state.filterSelectedPartilhados,
      referenciados: state.filterSelectedReferenciados,
      searchLevel: state.filterSelectedSearchLevel.value,
    }),
    filterData: state => ({
      tiposClienteLead: state.filterDataTiposClienteLead,
      tiposClienteContacto: state.filterDataTiposClienteContacto,
      bookmarksByUser: state.filterDataBookmarksByUser,
      classificacaoLead: state.filterDataClassificacaoLead,
      estadosLeadComprador: state.filterDataEstadosLeadComprador,
      estadosLeadVendedor: state.filterDataEstadosLeadVendedor,
      allUsersAssocLeader: state.filterDataAllUsersAssocLeader,
      consentimento: state.filterDataConsentimento,
      totalLeadsRGPD: state.filterDataTotalLeadsRGPD,
      totalLeadsRecebidas: state.filterDataTotalLeadsRecebidas,
      totalLeadsRecebidasNew: state.filterDataTotalLeadsRecebidasNew,
      totalLeadsReferenciados: state.filterDataTotalLeadsReferenciados,
      totalLeadsReferenciadosNew: state.filterDataTotalLeadsReferenciadosNew,
      totalImportados: state.filterDataTotalImportados,
      totalImportadosFilesNoProcess: state.filterDataTotalImportadosFilesNoProcess,
      tabsListview: state.filterDataTabsListview,
      showItensLeadsRefShares: state.filterDataShowItensLeadsRefShares,
      actionEditBookmarksTxt: state.filterDataActionEditBookmarksTxt,
      actionEditTabsTxt: state.filterDataActionEditTabsTxt,
      actionImportLeads: state.filterDataActionImportLeads,
      rgpdSituacoesPendentes: state.filterDataRGPDSituacoesPendentes,
      showResponsableUser: state.filterDataShowResponsableUser,
      showTiposClienteLead: state.filterDataShowTiposClienteLead,
      showTiposClienteContacto: state.filterDataShowTiposClienteContacto,
      showBookmarksByUser: state.filterDataShowBookmarksByUser,
      showClassificacaoLead: state.filterDataShowClassificacaoLead,
      showConsentimento: state.filterDataShowConsentimento,
      showRGPDSituacoesPendentes: state.filterDataShowRGPDSituacoesPendentes,
      showCreateLeads: state.filterDataShowCreateLeads,
      showCreateContacto: state.filterDataShowCreateContacto,
      load: state.filterDataLoad,
      totalLeadsPortais: state.filterDataTotalLeadsPortais,
    }),
    listview_txt_default: state => ({
      txt: state.listview_txt_default,
      txt_not_find: state.listiview_txt_not_find,
      txt_search: state.listiview_txt_search,
      txt_loading: state.listview_txt_loading,
    }),
    permsUser: state => ({
      canCreateContacto: state.canCreateContacto.value,
      canCreateLeads: state.canCreateLeads.value,
    }),
    listviewInit: state => state.listviewInit.value,
    listviewData: state => state.listviewData.value,
    listviewOrderBy: state => state.listviewOrderBy.value,
    totalRows: state => state.totalRows.value,
    perPage: state => state.perPage.value,
    tabSelectFilter: state => state.tabSelectFilter.value,
    loadingListview: state => state.loadingListview.value,
    currentPage: state => state.currentPage.value,
    searchTxt: state => state.searchTxt.value,
    fieldsOrder: state => {
      if (state.tabSelectFilter.value === 'contactos') {
        return state.aFieldsOrder.filter(o => o.name !== 'angariado')
      }

      return state.aFieldsOrder
    },
    positionListview: state => state.positionListview,
    showPager: state => {
      if (state.totalRows.value > state.perPage.value) {
        return true
      }

      return false
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setLoadingListview(state, payload) {
      state.loadingListview.value = payload
    },
    setListviewData(state, payload) {
      state.listviewData.value = payload
    },
    setTotalRows(state, payload) {
      state.totalRows.value = payload
    },
    setFilterData(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setFilterSelected(state, payload) {
      const index = Object.keys(payload)[0]
      state[index].value = payload[index]
    },
    setSearchTxt(state, payload) {
      state.searchTxt.value = payload
    },
    setCurrentPage(state, payload) {
      state.currentPage.value = payload
    },
    setUserResponsable(state, payload) {
      state.filterSelectedResponsableUser.value = payload
    },
    setShowItensLeadsRefShares(state, payload) {
      state.filterDataShowItensLeadsRefShares.value = payload
    },
    setTabSelectFilter(state, payload) {
      state.tabSelectFilter.value = payload
    },
    setListviewOrderBy(state, payload) {
      state.listviewOrderBy.value = {
        sort: payload.sort,
        column: payload.column,
      }
    },
    setSelectedLeadsID(state, payload) {
      state.filterSelectedLeadsID.value = payload ? state.listviewData.value.map(row => row.id) : []
    },
    setBookmarksCombinationByUser(state, payload) {
      state.filterSelectedBookmarksCombinationByUser.value = payload
    },
    setCanCreateContacto(state, payload) {
      state.canCreateContacto.value = payload
    },
    setCanCreateLeads(state, payload) {
      state.canCreateLeads.value = payload
    },
    setListviewInit(state, payload) {
      state.listviewInit.value = payload
    },
    addFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value += 1
    },
    resetFilterSelectedTotalFields(state) {
      state.filterSelectedTotalFields.value = 0
    },
    resetAllFilterSelected(state) {
      state.filterSelectedTiposClienteLead.value = ['all']
      state.filterSelectedTiposClienteContacto.value = ['all']
      state.filterSelectedBookmarksByUser.value = ['all']
      state.filterSelectedClassificacaoLead.value = ['all']
      state.filterSelectedEstadosLeadComprador.value = ['all']
      state.filterSelectedEstadosLeadVendedor.value = ['all']
      state.filterSelectedConsentimento.value = ['all']
      state.filterSelectedResponsableUser.value = { nome: 'Todos', id: '' }
      state.filterSelectedShowStatusComprador.value = false
      state.filterSelectedShowStatusVendedor.value = false
      state.filterSelectedBookmarksCombinationByUser.value = false
      state.filterSelectedRGPDSituacoesPendentes.value = ['all']
      state.filterSelectedTotalFields.value = 0
      state.filterSelectedPartilhados.value = ''
      state.filterSelectedReferenciados.value = ''
      state.searchTxt.value = ''
      state.filterSelectedSearchLevel.value = []
    },
    setShowFiltersListviewByTabSelected(state) {
      // Botão de criação
      state.filterDataShowCreateLeads.value = false
      state.filterDataShowCreateContacto.value = false

      if (['contactos'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowCreateContacto.value = true
      }

      if (['RGPD', 'lixo', 'contactos', 'importados'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowCreateLeads.value = false
      } else {
        state.filterDataShowCreateLeads.value = true
      }

      // Responsavel
      if (['contactos', 'leads', 'leads_do_leader', 'RGPD', 'minhas_leads', 'importados', 'all_leads_contacts'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowResponsableUser.value = false
        state.filterSelectedResponsableUser.value = { nome: i18n.t('Todos'), id: '' }
      } else {
        state.filterDataShowResponsableUser.value = true
      }

      // Tipo de cliente :: Contactos
      if (['contactos'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowTiposClienteContacto.value = true
      } else {
        state.filterDataShowTiposClienteContacto.value = false
        state.filterSelectedTiposClienteContacto.value = ['all']
      }

      // Tipo de cliente :: Leads
      if (['RGPD', 'importados'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowTiposClienteLead.value = false
        state.filterSelectedTiposClienteLead.value = ['all']
      } else {
        state.filterDataShowTiposClienteLead.value = true
      }

      // Marcadores
      if (['RGPD', 'importados'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowBookmarksByUser.value = false
        state.filterSelectedBookmarksByUser.value = ['all']
      } else {
        state.filterDataShowBookmarksByUser.value = true
      }

      // Classificacao
      if (['RGPD', 'importados'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowClassificacaoLead.value = false
        state.filterSelectedClassificacaoLead.value = ['all']
      } else {
        state.filterDataShowClassificacaoLead.value = true
      }

      // Consentimento
      if (['importados'].includes(state.tabSelectFilter.value)) {
        state.filterSelectedConsentimento.value = ['all']
        state.filterDataShowConsentimento.value = false
      } else {
        state.filterDataShowConsentimento.value = true
      }

      // RGPD - Situacoes pendentes
      if (['RGPD'].includes(state.tabSelectFilter.value)) {
        state.filterDataShowRGPDSituacoesPendentes.value = true
      } else {
        state.filterDataShowRGPDSituacoesPendentes.value = false
        state.filterSelectedRGPDSituacoesPendentes.value = ['all']
      }
    },
    savePositionListview(state, payload) {
      state.positionListview = payload || {}
    },
    setHistoryKeyStorage(state, payload) {
      state.historyKeyStorage = payload || ''
    },
    saveStates(state) {
      if (state.historyKeyStorage !== '') {
        const oStatesHistory = []

        state.statesToLocalStorage.forEach(elm => {
          if (state[elm] !== undefined) {
            oStatesHistory.push({
              key: elm,
              value: state[elm],
            })
          }
        })

        localStorage.setItem(state.historyKeyStorage, JSON.stringify(oStatesHistory))
      }
    },
    initStates(state) {
      if (state.historyKeyStorage !== '') {
        if ((localStorage.getItem(state.historyKeyStorage) !== null) && (localStorage.getItem(state.historyKeyStorage) !== '')) {
          try {
            const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))

            if (stateCustom.length > 0) {
              // Verifica se tem o tabulador selecionado, senao fica perde as preferencias todas
              let bSkipLoadPreferencesListview = false

              stateCustom.forEach(field => {
                if (('key' in field) && ('value' in field)) {
                  if (state.statesToLocalStorage.includes(field.key)) {
                    if (field.key === 'tabSelectFilter') {
                      if (field.value.value === '') {
                        bSkipLoadPreferencesListview = true
                      }
                      if (field.value.value === 'portais_imobiliarios' && state.filterDataTotalLeadsPortais.value <= 0) {
                        bSkipLoadPreferencesListview = true
                      }
                    }
                  }
                }
              })

              if (bSkipLoadPreferencesListview === false) {
                stateCustom.forEach(field => {
                  if (('key' in field) && ('value' in field)) {
                    if (state.statesToLocalStorage.includes(field.key)) {
                      Object.assign(state[field.key], field.value)
                    }
                  }
                })
              }
            }
          } catch (err) {
            localStorage.removeItem(state.historyKeyStorage)
            //
          }
        }
      }
    },
  },
  actions: {
    // Carregar listagem
    async fetchListView({
      state, commit, dispatch, rootGetters,
    }, payload) {
      const listviewFilterParams = new FormData()

      commit('resetFilterSelectedTotalFields')

      commit('setSelectedLeadsID', '')

      // Verifica se mostra o botão de criar novo registo (Lead / Contacto)
      const currentUser = rootGetters['auth/currentUser']

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if ('extras' in currentUser) {
          if (state.tabSelectFilter.value === 'contactos') {
            if (
              ((currentUser.extras.cargos !== undefined) && (currentUser.extras.cargos.includes(0)))
            || ((currentUser.extras.perm !== undefined) && (Object.values(currentUser.extras.perm).includes('contactos_C')))
            || (currentUser.extras.basic === 1)
            ) {
              commit('setCanCreateContacto', true)
            } else {
              commit('setCanCreateContacto', false)
            }
          } else if (currentUser.extras.basic === 1) {
            commit('setCanCreateLeads', true)
          } else {
            commit('setCanCreateLeads', false)
            if ((currentUser.extras.perm !== undefined) && (Object.values(currentUser.extras.perm).includes(`${state.tabSelectFilter.value}_C`))) {
              commit('setCanCreateLeads', true)
            }
          }
        }
      }

      if ((state.tabSelectFilter.value === 'contactos')) {
        if (state.filterSelectedTiposClienteContacto.value.length > 0) {
          state.filterSelectedTiposClienteContacto.value.forEach(val => {
            listviewFilterParams.append('fsw027s03[]', val)

            if (val !== 'all') {
              commit('addFilterSelectedTotalFields')
            }
          })
        }
      } else if (state.filterSelectedTiposClienteLead.value.length > 0) {
        state.filterSelectedTiposClienteLead.value.forEach(val => {
          listviewFilterParams.append('fsw027s03[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if (state.filterSelectedEstadosLeadComprador.value.length > 0) {
        state.filterSelectedEstadosLeadComprador.value.forEach(val => {
          listviewFilterParams.append('fsw025s07[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if (state.filterSelectedEstadosLeadVendedor.value.length > 0) {
        state.filterSelectedEstadosLeadVendedor.value.forEach(val => {
          listviewFilterParams.append('fsw025s60[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if (state.filterSelectedClassificacaoLead.value.length > 0) {
        state.filterSelectedClassificacaoLead.value.forEach(val => {
          listviewFilterParams.append('fsw025s08[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if (state.filterSelectedBookmarksByUser.value.length > 0) {
        state.filterSelectedBookmarksByUser.value.forEach(val => {
          listviewFilterParams.append('fsw076s03[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })

        if (state.filterSelectedBookmarksCombinationByUser.value === true) {
          listviewFilterParams.append('fsw076s03combo', true)
          commit('addFilterSelectedTotalFields')
        }
      }

      if (state.filterSelectedConsentimento.value.length > 0) {
        state.filterSelectedConsentimento.value.forEach(val => {
          listviewFilterParams.append('fsw025s197[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if (state.filterSelectedRGPDSituacoesPendentes.value.length > 0) {
        state.filterSelectedRGPDSituacoesPendentes.value.forEach(val => {
          listviewFilterParams.append('fsw254s09[]', val)

          if (val !== 'all') {
            commit('addFilterSelectedTotalFields')
          }
        })
      }

      if ((state.filterSelectedResponsableUser.value !== null) && (state.filterSelectedResponsableUser.value !== undefined) && ('id' in state.filterSelectedResponsableUser.value) && (state.filterSelectedResponsableUser.value.id !== 'id') && (state.filterSelectedResponsableUser.value.id !== '')) {
        listviewFilterParams.append('fsw069s03', state.filterSelectedResponsableUser.value.id)
        commit('addFilterSelectedTotalFields')
      }

      if (state.searchTxt.value !== '') {
        listviewFilterParams.append('fSearch', state.searchTxt.value)
        commit('addFilterSelectedTotalFields')
      }

      if (state.filterSelectedPartilhados.value === 'all') {
        listviewFilterParams.append('fsw025s75', 'all')
        commit('addFilterSelectedTotalFields')
      }

      if (state.filterSelectedReferenciados.value === 'all') {
        listviewFilterParams.append('fsw025s116', 'all')
        commit('addFilterSelectedTotalFields')
      }

      if (state.filterSelectedSearchLevel.value.length > 0) {
        state.filterSelectedSearchLevel.value.forEach(val => {
          listviewFilterParams.append('fSearchLevel[]', val)
          commit('addFilterSelectedTotalFields')
        })
      }

      if (state.listviewOrderBy.value.column !== undefined) {
        if (state.tabSelectFilter.value === 'contactos') {
          if (state.listviewOrderBy.value.column === 'angariado') {
            commit('setListviewOrderBy', {
              sort: 'desc',
              column: 'lastChange',
            })
          }
        }

        listviewFilterParams.append('orderByColumn', state.listviewOrderBy.value.column)
      }

      if (state.listviewOrderBy.value.sort !== undefined) {
        listviewFilterParams.append('orderBySort', state.listviewOrderBy.value.sort)
      }

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if ('extras' in currentUser) {
          if (currentUser.extras.basic === 1) {
            if (state.tabSelectFilter.value === 'contactos') {
              listviewFilterParams.append('tabSelectFilter', '')
            } else {
              listviewFilterParams.append('tabSelectFilter', state.tabSelectFilter.value)
            }
          } else {
            listviewFilterParams.append('tabSelectFilter', state.tabSelectFilter.value)
          }
        }
      }

      // Por defeito, caso nao seja selecionada a pagina, faz sempre reset para a pagina "1"
      if ((payload !== null) && (payload !== undefined) && ('skipCurrentPage' in payload) && (payload.skipCurrentPage === true)) {
        // Fix para nao alterar a pagina que foi selecionada pelo utilizador
      } else if (state.currentPage.value !== 1) {
        commit('setCurrentPage', 1)
      }

      listviewFilterParams.append('fstartLetter', 'all')
      listviewFilterParams.append('loadContentRecords', 1)
      listviewFilterParams.append('start', ((state.currentPage.value - 1) * state.perPage.value))
      listviewFilterParams.append('length', state.perPage.value)

      let loadListviewCustom = ''

      if (state.tabSelectFilter.value === 'RGPD') {
        loadListviewCustom = 'leads/loadlistviewRGPD'
      } else if (state.tabSelectFilter.value === 'contactos') {
        loadListviewCustom = 'leads/loadlistviewContactos'
      } else {
        loadListviewCustom = 'leads/loadlistview'
      }

      if (!!payload && (payload.loadingDisable !== undefined) && (payload.loadingDisable === true)) {
        commit('setListviewInit', 1)
      } else {
        commit('setListviewData', [])
        commit('setTotalRows', 0)
        commit('setLoadingListview', true)
        commit('setListviewInit', 1)
      }

      commit('saveStates')

      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadlistviewRGPD', { root: true })
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadlistviewContactos', { root: true })
      await dispatch('request/cancelTokensPendingByUrl', 'leads/loadlistview', { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}${loadListviewCustom}`, listviewFilterParams)
          .then(response => {
            if (response.data.data !== undefined) {
              commit('setListviewData', response.data.data)
              commit('setTotalRows', response.data.recordsTotal)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              dispatch('showItensLeadsRefShares')
              resolve(true)
            } else {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error('Problema ao carregar a listagem'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              commit('setListviewData', [])
              commit('setTotalRows', 0)
              commit('setLoadingListview', false)
              commit('setListviewInit', 0)
              reject(new Error(i18n.t('Problema ao carregar a listagem')))
            }
          })
      })
    },

    // Obter os filtros da listagem
    async getDataFiltersListview({ state, commit, rootGetters }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setFilterData', { filterDataTiposClienteLead: response.data.data.tiposClienteLead })
                commit('setFilterData', { filterDataTiposClienteContacto: response.data.data.tiposClienteContacto })
                commit('setFilterData', { filterDataBookmarksByUser: response.data.data.bookmarksByUser })
                commit('setFilterData', { filterDataClassificacaoLead: response.data.data.classificacaoLead })
                commit('setFilterData', { filterDataEstadosLeadComprador: response.data.data.estadosLeadComprador })
                commit('setFilterData', { filterDataEstadosLeadVendedor: response.data.data.estadosLeadVendedor })
                commit('setFilterData', { filterDataConsentimento: response.data.data.consentimentoFilter })
                commit('setFilterData', { filterDataTotalLeadsRGPD: response.data.data.totalLeadsRGPD })
                commit('setFilterData', { filterDataTotalLeadsRecebidas: response.data.data.totalLeadsRecebidas })
                commit('setFilterData', { filterDataTotalLeadsRecebidasNew: response.data.data.totalLeadsRecebidasNew })
                commit('setFilterData', { filterDataTotalLeadsReferenciados: response.data.data.totalLeadsReferenciados })
                commit('setFilterData', { filterDataTotalLeadsReferenciadosNew: response.data.data.totalLeadsReferenciadosNew })
                commit('setFilterData', { filterDataTotalImportados: response.data.data.totalImportados })
                commit('setFilterData', { filterDataTotalImportadosFilesNoProcess: response.data.data.importados_totalFilesNoProcess })
                commit('setFilterData', { filterDataRGPDSituacoesPendentes: response.data.data.rgpdSituacoesPendentes })
                commit('setFilterData', { filterDataTotalLeadsPortais: Number(response.data.data.totalLeadsPortais) })
              }

              // Obter os separadores disponiveis para o utilizador autenticado
              const currentUser = rootGetters['auth/currentUser']
              const atabsListview = []

              if ((currentUser !== null) && (currentUser !== undefined)) {
                if ('extras' in currentUser) {
                  if (currentUser.extras.basic === 1) {
                    atabsListview.push({
                      key: 'result_search',
                      text: i18n.t('Resultados da pesquisa'),
                      show: false,
                    })

                    atabsListview.push({
                      key: 'all_leads_contacts',
                      text: i18n.t('Todos'),
                      show: true,
                    })

                    atabsListview.push({
                      key: 'RGPD',
                      text: i18n.t('RGPD'),
                      show: ((state.filterDataTotalLeadsRGPD.value > 0) ? Boolean(true) : Boolean(false)),
                    })

                    atabsListview.push({
                      key: 'contactos',
                      text: i18n.t('Contactos'),
                      show: true,
                    })

                    // Para tipo de utilizador: Consultor
                    if ([4].includes(currentUser.id_role)) {
                      if ((response.data.data !== undefined) && (response.data.data.tabsTeamLead !== undefined)) {
                        if (Object.keys(response.data.data.tabsTeamLead).length > 0) {
                          Object.keys(response.data.data.tabsTeamLead).forEach(index => {
                            const showTabDefault = (!(state.aTabsHiddenStart.value.includes(index)))

                            if (!['portais_imobiliarios'].includes(index)) {
                              atabsListview.push({
                                key: index,
                                text: response.data.data.tabsTeamLead[index],
                                show: showTabDefault,
                              })
                            }
                          })
                        }
                      } else {
                        atabsListview.push({
                          key: 'leads',
                          text: i18n.t('Leads'),
                          show: true,
                        })
                      }

                      atabsListview.push({
                        key: 'lixo',
                        text: i18n.t('Lixo'),
                        show: true,
                      })
                    } else {
                      atabsListview.push({
                        key: 'leads',
                        text: i18n.t('Leads'),
                        show: true,
                      })
                    }

                    atabsListview.push({
                      key: 'portais_imobiliarios',
                      text: i18n.t('Portais Imobiliários'),
                      show: ((state.filterDataTotalLeadsPortais.value > 0) ? Boolean(true) : Boolean(false)),
                    })

                    atabsListview.push({
                      key: 'importados',
                      text: i18n.t('Importados'),
                      show: false, // totalImportados == 0) and (importados_totalFilesNoProcess - Desativo para ja
                    })
                  } else {
                    atabsListview.push({
                      key: 'result_search',
                      text: i18n.t('Resultados da pesquisa'),
                      show: false,
                    })

                    atabsListview.push({
                      key: 'all_leads_contacts',
                      text: i18n.t('Todos'),
                      show: true,
                    })

                    atabsListview.push({
                      key: 'RGPD',
                      text: i18n.t('RGPD'),
                      show: ((state.filterDataTotalLeadsRGPD.value > 0) ? Boolean(true) : Boolean(false)),
                    })

                    atabsListview.push({
                      key: 'contactos',
                      text: i18n.t('Contactos'),
                      show: ((Object.values(currentUser.extras.perm).includes('contactos_V')) ? Boolean(true) : Boolean(false)),
                    })

                    if ((response.data.data !== undefined) && (Object.keys(response.data.data.tabsTeamLead).length > 0)) {
                      Object.keys(response.data.data.tabsTeamLead).forEach(index => {
                        if (response.data.data.tabsTeamLead[index].tabLeads === 1) {
                          const showTabDefault = (!(state.aTabsHiddenStart.value.includes(index)))

                          if (Object.values(currentUser.extras.perm).includes(`${index}_V`)) {
                            if (response.data.data.tabsDescCustom[index]) {
                              atabsListview.push({
                                key: index,
                                text: response.data.data.tabsDescCustom[index],
                                show: showTabDefault,
                              })
                            } else if (!['portais_imobiliarios'].includes(index)) {
                              atabsListview.push({
                                key: index,
                                text: response.data.data.tabsTeamLead[index].desc,
                                show: showTabDefault,
                              })
                            }
                          }
                        }
                      })
                    }

                    atabsListview.push({
                      key: 'portais_imobiliarios',
                      text: i18n.t('Portais Imobiliários'),
                      show: (((Object.values(currentUser.extras.perm).includes('portais_imobiliarios_V') || Object.values(currentUser.extras.perm).includes('portais_imobiliarios_VC')
                        || Object.values(currentUser.extras.perm).includes('portais_imobiliarios_E') || Object.values(currentUser.extras.perm).includes('portais_imobiliarios_T'))
                        && state.filterDataTotalLeadsPortais.value > 0) ? Boolean(true) : Boolean(false)),
                    })

                    atabsListview.push({
                      key: 'importados',
                      text: i18n.t('Importados'),
                      show: false, // (((state.filterDataTotalImportados === 0) && (state.filterDataTotalImportadosFilesNoProcess === 0)) ? Boolean(false) : Boolean(true)), // totalImportados == 0) and (importados_totalFilesNoProcess Desativo para ja
                    })
                  }
                }
              }

              commit('setFilterData', { filterDataTabsListview: atabsListview })

              // Caso seja um membro de equipa os tipos de cliente e conforme o cargo de especialista
              if ((currentUser !== null) && (currentUser !== undefined)) {
                if ('extras' in currentUser) {
                  if ((currentUser.extras.basic !== 1) && (currentUser.id_role === 4)) {
                  // Tipos de cliente :: Leads
                    if (state.filterDataTiposClienteLead.value.length > 0) {
                      let aNewTiposClienteLead = []

                      state.filterDataTiposClienteLead.value.forEach(oTipo => {
                        if (currentUser.extras.tipocliente.includes('3')) {
                          aNewTiposClienteLead.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('1') && currentUser.extras.tipocliente.includes(2)) {
                          aNewTiposClienteLead.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('1') && ['2', '4'].includes(oTipo.sw022s01)) {
                          aNewTiposClienteLead.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('2') && ['1', '3'].includes(oTipo.sw022s01)) {
                          aNewTiposClienteLead.push(oTipo)
                        }
                      })

                      commit('setFilterData', { filterDataTiposClienteLead: aNewTiposClienteLead })
                      aNewTiposClienteLead = []
                    }

                    // Tipos de cliente :: Contacto
                    if (state.filterDataTiposClienteContacto.value.length > 0) {
                      let aNewTiposClienteContacto = []

                      state.filterDataTiposClienteContacto.value.forEach(oTipo => {
                        if (currentUser.extras.tipocliente.includes('3')) {
                          aNewTiposClienteContacto.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('1') && currentUser.extras.tipocliente.includes(2)) {
                          aNewTiposClienteContacto.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('1') && ['2', '4'].includes(oTipo.sw022s01)) {
                          aNewTiposClienteContacto.push(oTipo)
                        } else if (currentUser.extras.tipocliente.includes('2') && ['1', '3'].includes(oTipo.sw022s01)) {
                          aNewTiposClienteContacto.push(oTipo)
                        }
                      })

                      commit('setFilterData', { filterDataTiposClienteContacto: aNewTiposClienteContacto })
                      aNewTiposClienteContacto = []
                    }
                  }
                }
              }

              // Acrescenta a opção "TODOS" na lista de responsaveis
              if ((response.data.data !== undefined) && (response.data.data.allUsersAssocLeader !== undefined)) {
                if (response.data.data.allUsersAssocLeader.length > 0) {
                  const aUsersAssocLeaderDefault = {
                    id: '',
                    nome: i18n.t('Todos'),
                  }

                  response.data.data.allUsersAssocLeader.unshift(aUsersAssocLeaderDefault)
                }

                commit('setFilterData', { filterDataAllUsersAssocLeader: response.data.data.allUsersAssocLeader })
              }

              // Funcionalidades
              if ((currentUser !== null) && (currentUser !== undefined)) {
                if ('extras' in currentUser) {
                  // Ação :: importar
                  if (
                    (response.data.data !== undefined)
                    && ('userExportImportPerm' in response.data.data)
                    && ('showButtonImportLeads' in response.data.data)
                    && (response.data.data.userExportImportPerm === 1)
                    && (response.data.data.showButtonImportLeads === 1)
                  ) {
                    // commit('setFilterData', { filterDataActionImportLeads: true })
                    commit('setFilterData', { filterDataActionImportLeads: false }) // Neste momento a funcionalidade encontra-se desativa na app mobile
                  }

                  // Ação :: Editar separadores
                  if (
                    ((currentUser.extras.cargos !== undefined) && (currentUser.extras.cargos.includes(0)) && ('id_role' in currentUser) && ([7].includes(Number(currentUser.id_role))))
                    || ((currentUser.extras.basic === 1) && (('id_role' in currentUser) && ([4].includes(Number(currentUser.id_role)))))
                  ) {
                    commit('setFilterData', { filterDataActionEditTabsTxt: true })
                  }

                  // Ação :: Editar marcadores
                  if (
                    ((currentUser.extras.cargos !== undefined) && (currentUser.extras.cargos.includes(0)))
                    || ((currentUser.extras.perm !== undefined) && (Object.values(currentUser.extras.perm).includes('edita_marcadores')))
                    || (currentUser.extras.basic === 1)
                  ) {
                    commit('setFilterData', { filterDataActionEditBookmarksTxt: true })
                  }
                }
              }

              // Indica que esta a informação completa
              commit('setFilterData', { filterDataLoad: true })

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao carregar o filtro')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o filtro')))
            }
          })
      })
    },

    // Ativa os tabuladores que tem registos
    async showTabsListviewWithRecords({ state, commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}leads/loadSeparadoresDisponiveis`)
          .then(response => {
            if (response.data.separadoresDisponiveis !== undefined) {
              if (
                (response.data.separadoresDisponiveis !== undefined)
              && (response.data.separadoresDisponiveis.length > 0)
              && (state.filterDataTabsListview.value.length > 0)
              ) {
                const atabsListview = state.filterDataTabsListview.value

                if (atabsListview.length > 0) {
                  atabsListview.forEach((oTab, kTab) => {
                    if (response.data.separadoresDisponiveis.includes(oTab.key)) {
                      atabsListview[kTab].show = true
                    }
                  })
                }

                const stateCustom = JSON.parse(localStorage.getItem(state.historyKeyStorage))
                if (stateCustom !== null && stateCustom.length > 0) {
                  stateCustom.forEach(field => {
                    if (('key' in field) && ('value' in field)) {
                      if (state.statesToLocalStorage.includes(field.key)) {
                        if (field.key === 'tabSelectFilter') {
                          if (!response.data.separadoresDisponiveis.includes(field.value.value)) {
                            commit('setTabSelectFilter', 'all_leads_contacts')
                          }
                        }
                      }
                    }
                  })
                }

                commit('setFilterData', { filterDataTabsListview: atabsListview })
              }
              resolve(true)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os separadores')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os separadores')))
            }
          })
      })
    },

    // Carrega os filtros conforme o separador ativo
    async showFiltersListview({ state, commit, dispatch }) {
      if (state.filterDataTabsListview.value.length > 0) {
        const oTabsListviewActive = state.filterDataTabsListview.value.filter(oTab => oTab.show === true)

        try {
          if (oTabsListviewActive[0].key) {
            commit('setTabSelectFilter', oTabsListviewActive[0].key)
            dispatch('showFiltersListviewByTabSelected')
          }
        } catch {
        //
        }
      }
    },

    showFiltersListviewByTabSelected({ commit }) {
      commit('setShowFiltersListviewByTabSelected')
      commit('setFilterData', { filterDataLoad: true })
    },

    // Verificar se mostra nos filtros o filtro dos "Leads referenciados / partilhados"
    showItensLeadsRefShares({ commit, state, rootGetters }) {
      const currentUser = rootGetters['auth/currentUser']

      if ((currentUser !== null) && (currentUser !== undefined)) {
        if (('extras' in currentUser) && (currentUser.extras.basic === 1)) {
          // Consultor individual
          if (state.tabSelectFilter.value === 'leads') {
            commit('setShowItensLeadsRefShares', true)
          } else {
            commit('setShowItensLeadsRefShares', false)
          }
        } else {
          // Consultor / membro de equipa
          const tabs = ['leads_do_leader', 'minhas_leads']
          if (tabs.includes(state.tabSelectFilter.value)) {
            commit('setShowItensLeadsRefShares', true)
          } else {
            commit('setShowItensLeadsRefShares', false)
          }
        }
      }
    },

    // Ordenação da listagem
    listviewFilterOrderBy({ state, commit, dispatch }, field) {
      if ((!!state.listviewOrderBy.value.column) && (state.listviewOrderBy.value.column === field)) {
        if (state.listviewOrderBy.value.sort === 'asc') {
          commit('setListviewOrderBy', {
            sort: 'desc',
            column: field,
          })
        } else {
          commit('setListviewOrderBy', {
            sort: 'asc',
            column: field,
          })
        }
      } else {
        commit('setListviewOrderBy', {
          sort: 'asc',
          column: field,
        })
      }

      commit('setCurrentPage', 1)

      return dispatch('fetchListView')
    },

    setLeadIDSelected({ commit }, idLead) {
      commit('setSelectedLeadsID', idLead)
    },

    setSearchTxt({ commit, dispatch }, value) {
      commit('setSearchTxt', value)
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    setCurrentPage({ commit, dispatch }, value) {
      commit('setCurrentPage', value)
      return dispatch('fetchListView', { skipCurrentPage: true })
    },

    pullToRefreshListview({ commit, dispatch }) {
      commit('setCurrentPage', 1)
      return dispatch('fetchListView', { loadingDisable: true })
    },

    // Metodos do filtro lateral - Escolher responsavel
    listviewFilterUserResponsable({ commit, dispatch }, oResponsableSelected) {
      if ((oResponsableSelected !== null) && (!!oResponsableSelected.id)) {
        let oResponsable = {}
        oResponsable = oResponsableSelected
        oResponsable.id = btoa(oResponsableSelected.id)

        commit('setUserResponsable', oResponsable)
      } else {
        commit('setUserResponsable', oResponsableSelected)
      }

      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Escolher separador
    listviewFilterTab({ commit, dispatch }, tab) {
      commit('setTabSelectFilter', tab)
      commit('resetAllFilterSelected')
      commit('setShowFiltersListviewByTabSelected')
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Partilhados / Referenciados
    listviewFilterTabField({ commit, dispatch }, tab) {
      if (tab === 'leads_partilhadas') {
        commit('setFilterSelected', { filterSelectedPartilhados: 'all' })
        commit('setFilterSelected', { filterSelectedReferenciados: '' })
      }
      if (tab === 'leads_referenciados') {
        commit('setFilterSelected', { filterSelectedPartilhados: '' })
        commit('setFilterSelected', { filterSelectedReferenciados: 'all' })
      }
      if (tab === 'leads_all') {
        commit('setFilterSelected', { filterSelectedPartilhados: '' })
        commit('setFilterSelected', { filterSelectedReferenciados: '' })
      }
      return dispatch('fetchListView')
    },

    // Metodos do filtro lateral - Filtro geral dos blocos
    listviewFilterGeral({ state, commit, dispatch }, params) {
      try {
        const nameParam = Object.keys(params)[0]
        const valueParam = params[nameParam]

        const indexItem = state[nameParam].value.indexOf(valueParam)
        if (indexItem === -1) {
          state[nameParam].value.push(valueParam)
        } else {
          state[nameParam].value.splice(indexItem, 1)
        }

        if (state[nameParam].value.includes('all')) {
          const indexItemAll = state[nameParam].value.indexOf('all')
          state[nameParam].value.splice(indexItemAll, 1)
        }

        if (state[nameParam].value.length === 0) {
          state[nameParam].value.push('all')
        }

        if (valueParam === 'all') {
          state[nameParam].value = []
          state[nameParam].value.push('all')
        }

        if (nameParam === 'filterSelectedTiposClienteLead') {
          if (state[nameParam].value.includes('2') || state[nameParam].value.includes('4')) {
            state.filterSelectedShowStatusComprador.value = true
          } else {
            state.filterSelectedShowStatusComprador.value = false
            state.filterSelectedEstadosLeadComprador.value = []
            state.filterSelectedEstadosLeadComprador.value.push('all')
          }

          if (state[nameParam].value.includes('1') || state[nameParam].value.includes('3')) {
            state.filterSelectedShowStatusVendedor.value = true
          } else {
            state.filterSelectedShowStatusVendedor.value = false
            state.filterSelectedEstadosLeadVendedor.value = []
            state.filterSelectedEstadosLeadVendedor.value.push('all')
          }
        }

        if (nameParam === 'filterSelectedBookmarksByUser') {
          if (state.filterSelectedBookmarksByUser.value.length < 2) {
            commit('setBookmarksCombinationByUser', false)
          }
        }

        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    // Limpar todos os filtros selecionados
    resetListviewFilterGeral({ commit, dispatch }) {
      commit('resetAllFilterSelected')
      return dispatch('fetchListView')
    },

    // Atualiza listagem dos marcadores da listagem
    async updateListviewBookmarks({ commit }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                commit('setFilterData', { filterDataBookmarksByUser: response.data.data.bookmarksByUser })
              }

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao atualizar os marcadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar os marcadores')))
            }
          })
      })
    },

    // Atualiza listagem dos separadores da listagem
    async updateListviewTabs({ state, rootGetters }) {
      return new Promise((resolve, reject) => {
        api.get(`${apiConfig.url_base_api}leads/list`)
          .then(response => {
            try {
              if (response.data.data !== undefined) {
                const currentUser = rootGetters['auth/currentUser']
                const aUpdateNamesTabs = []

                if ((currentUser !== null) && (currentUser !== undefined)) {
                  if (('extras' in currentUser) && (currentUser.extras.basic === 1)) {
                    if ([4].includes(currentUser.id_role)) {
                      if ((response.data.data !== undefined) && (Object.keys(response.data.data.tabsTeamLead).length > 0)) {
                        Object.keys(response.data.data.tabsTeamLead).forEach(index => {
                          aUpdateNamesTabs[index] = response.data.data.tabsTeamLead[index]
                        })
                      }
                    }
                  } else if ((response.data.data !== undefined) && (Object.keys(response.data.data.tabsTeamLead).length > 0)) {
                    Object.keys(response.data.data.tabsTeamLead).forEach(index => {
                      if (response.data.data.tabsTeamLead[index].tabLeads === 1) {
                        if (Object.values(currentUser.extras.perm).includes(`${index}_V`)) {
                          if (response.data.data.tabsDescCustom[index]) {
                            aUpdateNamesTabs[index] = response.data.data.tabsDescCustom[index]
                          } else {
                            aUpdateNamesTabs[index] = response.data.data.tabsTeamLead[index].desc
                          }
                        }
                      }
                    })
                  }
                }

                if (state.filterDataTabsListview.value.length > 0) {
                  state.filterDataTabsListview.value.forEach((row, index) => {
                    if (row.key in aUpdateNamesTabs) {
                      state.filterDataTabsListview.value[index].text = aUpdateNamesTabs[row.key]
                    }
                  })
                }
              }

              resolve(true)
            } catch (error) {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(i18n.t('Problema ao atualizar os separadores')))
              }
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao atualizar os separadores')))
            }
          })
      })
    },

    listviewFilterSearchLevel({ state, dispatch }, param) {
      try {
        const indexItem = state.filterSelectedSearchLevel.value.indexOf(param)
        if (indexItem === -1) {
          state.filterSelectedSearchLevel.value.push(param)
        } else {
          state.filterSelectedSearchLevel.value.splice(indexItem, 1)
        }

        return dispatch('fetchListView')
      } catch {
        return null
      }
    },

    async setInitialTabsDefault({ commit, state, rootGetters }, payload) {
      const currentUser = rootGetters['auth/currentUser']
      const listTabs = []
      const listTabsAll = []

      if (state.filterDataTabsListview.value.length > 0) {
        state.filterDataTabsListview.value.filter(oTab => oTab.show === true).forEach(row => {
          if ((row.key !== 'result_search') && (row.key !== 'contactos') && (row.key !== 'importados')) {
            listTabs.push(row.key)
          }
          listTabsAll.push(row.key)
        })

        if (payload.setMode === 'initialTab') {
          const firstTabSelected = listTabs.find(tab => tab !== undefined)
          commit('setTabSelectFilter', firstTabSelected)
        }

        if (payload.setMode === 'tabLeads') {
          if ((currentUser !== null) && (currentUser !== undefined)) {
            if (('extras' in currentUser) && (currentUser.extras.basic === 1)) {
              const tabSelect = listTabs.find(tab => tab === 'leads')
              if (tabSelect !== undefined) {
                commit('setTabSelectFilter', tabSelect)
              }
              //
            } else {
              // Consultor / membro de equipa
              let tabSelect = listTabs.find(tab => tab === 'leads_do_leader')
              if (tabSelect !== undefined) {
                commit('setTabSelectFilter', tabSelect)
              } else {
                tabSelect = listTabs.find(tab => tab === 'minhas_leads')
                if (tabSelect !== undefined) {
                  commit('setTabSelectFilter', tabSelect)
                }
              }
            }
          }
        }

        if (payload.setMode === 'tabRGPD') {
          const tabSelect = listTabsAll.find(tab => tab === 'RGPD')
          if (tabSelect !== undefined) {
            commit('setTabSelectFilter', tabSelect)
          }
        }

        if (payload.setMode === 'tabContactos') {
          const tabSelect = listTabsAll.find(tab => tab === 'contactos')
          if (tabSelect !== undefined) {
            commit('setTabSelectFilter', tabSelect)
          }
        }
      }
    },

  },
}
