<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >

        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrder.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrder"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Barra de ações dos registos da listagem -->
      <div
        v-if="listviewData.length > 0"
        class="app-action"
        :class="{'app-action-with_button': (filterSelected.leadsID.value.length > 0) }"
      >
        <div class="action-left align-items-center d-flex">
          <b-form-checkbox
            :checked="selectAllLeadsCheckbox"
            :indeterminate="isselectAllLeadsCheckboxIndeterminate"
            :disabled="checkButtonSelectAllIsDisabled"
            @change="selectAllCheckboxUpdate"
          >
            {{ $t('Selecionar tudo') }}
          </b-form-checkbox>
        </div>
        <div
          class="align-items-center d-flex"
        >
          <b-button
            v-if="filterSelected.leadsID.value.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            block
            size="sm"
            :style="{'margin-top': '-0.05rem'}"
            @click.stop.prevent="openModalActionByMultipleRows"
          >
            <span class="align-middle">{{ $t('Opções de seleção') }}</span>
          </b-button>
        </div>
      </div>

      <!-- Listagem -->
      <div
        class="email-user-list scroll-area"
      >
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
          @scroll.native="listViewSavePositionScroll"
        >
          <listview-list-data
            v-if="listviewData.length > 0"
            ref="list"
            :toggle-selected-lead="toggleSelectedLead"
            :open-modal-action-by-row="openModalActionByRow"
            :check-button-select-all-is-disabled="checkButtonSelectAllIsDisabled"
          />

          <div
            v-if="listviewData.length > 0"
            class="text-left pt-1 pb-1 pl-2 pr-2 border-1"
          >
            <h6 class="m-0">
              {{ $t('Total') }}: {{ totalRows }}
            </h6>
          </div>

          <div
            v-if="listviewData.length === 0"
            class="no-results"
            :class="{'show': !listviewData.length}"
          >
            <h5 v-if="(filterSelected.totalFields.value === 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(filterSelected.totalFields.value > 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['crm_leads/loadingListview']"
        no-wrap
      />

    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :create-new="createNew"
        :show-msg-error-request="showMsgErrorRequest"
        :form-gest-bookmarks="formGestBookmarks"
        :form-tabs-manager="formTabsManager"
        :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <bookmarks-modal-edition
      v-if="showBookmarksModalEdition===true"
      ref="bookmarksModalEdition"
    />

    <tabs-manager-edition
      v-if="showTabsManagerEdition===true"
      ref="tabsManagerEdition"
    />

    <modal-action
      v-if="showModalActionByRow===true"
      ref="modalAction"
      :row="rowModalAction"
      :extra="extraModalAction"
      :remove="removeSW025"
      :restore="restoreSW025"
      :share-lead="shareLead"
      :send-reference="sendReference"
      :share-lead-with-leader-or-consultor="shareLeadWithLeaderOrConsultor"
      :share-add-lead-with-leader-or-consultor="shareAddLeadWithLeaderOrConsultor"
      :change-tab-lead="changeTabLead"
      :convert-lead-to-contacto="convertLeadToContacto"
      :convert-contacto-to-lead="convertContactoToLead"
      :convert-contacto-to-lead-link="convertContactoToLeadLink"
      :convert-contacto-to-lead-choose-tab="convertContactoToLeadChooseTab"
      :modal-consentimento-action="modalConsentimentoAction"
      :save-lead-share-to-user="saveLeadShareToUser"
      :save-lead-ref-to-user="saveLeadRefToUser"
    />

    <modal-action-multiple-rows
      v-if="showModalActionByMultipleRows===true"
      ref="modalActionMultipleRows"
      :remove="removeSW025"
      :add-bookmarks="addBookmarksLeads"
      :change-tab-lead="changeTabLead"
      :assign-remove-exchange="shareLeadWithLeaderOrConsultor"
    />

    <modal-share-lead
      ref="modalShareLead"
    />

    <modal-share-reference
      ref="modalShareReference"
    />

    <modal-share-lead-with-leader-or-consultor
      ref="modalShareLeadWithLeaderOrConsultor"
    />

    <modal-change-tabs-leads
      ref="modalChangeTabsLeads"
    />

    <modal-convert-contact-to-lead-change-tabs
      ref="modalConvertContactToLeadChangeTabs"
    />

    <modal-link-consentimento
      v-if="showModalLinkConsentimento===true"
      ref="modalLinkConsentimento"
      :info-consentimento="modalConsentimentoActionInfo"
      :title="modalConsentimentoActionTitle"
      :close="modalConsentimentoActionClose"
    />

    <modal-add-bookmarks
      ref="modalAddBookmarks"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted, computed } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  BFormCheckbox, VBTooltip, BDropdown, BDropdownItem, BRow, BCol, BContainer, BPagination, BOverlay, BButton,
} from 'bootstrap-vue'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import crmLeadsListModule from '@store-modules/crm/leads/listview'
import crmLeadsEventsModule from '@store-modules/crm/leads/listview/events'
import { mapGetters } from 'vuex'
import { sizeIconsAction, showMsgRequest } from '@core-custom/mixins/geral'
import checkNested from 'check-nested'
import Vue from 'vue'
import { showModalDeleteMessage, useRouter } from '@core/utils/utils'
import ModalCheckInfo from '@/views/modules/crm/leads/form/form_data/modal/modalCheckInfo.vue'
import Ripple from 'vue-ripple-directive'
import VuePullRefresh from 'vue-pull-refresh'

import ListviewLeftSidebar from './leftSidebar.vue'
import ListviewListData from './listData.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    BButton,
    ListviewLeftSidebar,
    ListviewListData,
    VuePullRefresh,
    bookmarksModalEdition: () => import(/* webpackChunkName: "crm-leads-listview-bookmarks" */ /* webpackPrefetch: true */ '@/views/modules/bookmarks/modalEdition.vue'),
    tabsManagerEdition: () => import(/* webpackChunkName: "crm-leads-listview-tabs" */ /* webpackPrefetch: true */ '../tabsManager/modalEdition.vue'),
    ModalAction: () => import(/* webpackChunkName: "crm-leads-listview-modal-action" */ /* webpackPrefetch: true */ './modalAction.vue'),
    ModalActionMultipleRows: () => import(/* webpackChunkName: "crm-leads-listview-modal-action-multiple-rows" */ /* webpackPrefetch: true */ './ModalActionMultipleRows.vue'),
    ModalShareLead: () => import(/* webpackChunkName: "crm-leads-new-modal-share-lead" */ /* webpackPrefetch: true */ '../form/form_data/modal/shareLead.vue'),
    ModalShareReference: () => import(/* webpackChunkName: "crm-leads-new-modal-share-reference" */ /* webpackPrefetch: true */ '../form/form_data/modal/shareReference.vue'),
    ModalShareLeadWithLeaderOrConsultor: () => import(/* webpackChunkName: "crm-leads-new-modal-share-Lead-with-leader-or-consultor" */ /* webpackPrefetch: true */ '../form/form_data/modal/shareLeadWithLeaderOrConsultor.vue'),
    ModalChangeTabsLeads: () => import(/* webpackChunkName: "crm-leads-new-modal-change-tabs-leads" */ /* webpackPrefetch: true */ '../form/form_data/modal/changeTabsLeads.vue'),
    ModalConvertContactToLeadChangeTabs: () => import(/* webpackChunkName: "crm-leads-new-modal-convert-contact-to-lead-change-tabs" */ /* webpackPrefetch: true */ '../form/form_data/modal/convertContactToLeadChangeTabs.vue'),
    ModalLinkConsentimento: () => import(/* webpackChunkName: "crm-leads-new-modal-consentimento" */ /* webpackPrefetch: true */ '../form/form_data/modal/consentimento.vue'),
    ModalAddBookmarks: () => import(/* webpackChunkName: "crm-leads-new-modal-add-bookmarks" */ /* webpackPrefetch: true */ '../form/form_data/modal/modalAddBookmarks.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction, showMsgRequest],
  data() {
    return {
      showBookmarksModalEdition: false,
      showTabsManagerEdition: false,
      showModalActionByRow: false,
      showModalActionByMultipleRows: false,
      rowModalAction: {},
      extraModalAction: {},
      showModalLinkConsentimento: false,
      openModalActionRow: null,
      modalConsentimentoActionInfo: {},
      modalConsentimentoActionTitle: '',
    }
  },
  computed: {
    ...mapGetters('crm_leads', ['listviewData', 'filterData', 'filterSelected', 'listviewOrderBy', 'totalRows', 'perPage', 'listview_txt_default', 'listviewInit', 'fieldsOrder', 'tabSelectFilter', 'positionListview']),
    searchTxt: {
      get() { return this.$store.getters['crm_leads/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('crm_leads/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['crm_leads/currentPage'] },
      set(newValue) {
        this.$store.dispatch('crm_leads/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    selectAllLeadsCheckbox() {
      return this.listviewData.length && (this.listviewData.length === this.filterSelected.leadsID.value.length)
    },
    isselectAllLeadsCheckboxIndeterminate() {
      return (Boolean(this.filterSelected.leadsID.value.length) && this.listviewData.length !== this.filterSelected.leadsID.value.length)
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
    checkButtonSelectAllIsDisabled() {
      if (this.tabSelectFilter !== '') {
        if (this.tabSelectFilter === 'partilhadas') {
          return true
        }
        if (this.tabSelectFilter === 'lixo') {
          return true
        }
      }

      if (checkNested(this.filterSelected, 'partilhados.value') && (this.filterSelected.partilhados.value === 'all')) {
        return true
      }

      if (checkNested(this.filterSelected, 'referenciados.value') && (this.filterSelected.referenciados.value === 'all')) {
        return true
      }

      return false
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
    '$route.query': {
      immediate: false,
      handler(newVal) {
        this.loadFilterCustomQuery(newVal)
      },
    },
  },
  beforeDestroy() {
    this.$store.commit('crm_leads/savePositionListview', this.sPostionListview)
    this.$store.commit('crm_leads/saveStates')
  },
  async created() {
    try {
      this.$store.commit('crm_leads/setHistoryKeyStorage', this.$route.name)

      await this.$store.dispatch('crm_leads/getDataFiltersListview').catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$store.dispatch('crm_leads/showTabsListviewWithRecords').catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$store.dispatch('crm_leads/showFiltersListview')

      await this.loadFilterCustomQuery(this.routeParamsQuery)

      await this.$store.dispatch('crm_leads/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }
    } catch (err) {
      //
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('crm_leads/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    selectAllCheckboxUpdate(val) {
      this.$store.dispatch('crm_leads/setLeadIDSelected', val)
    },
    toggleSelectedLead(idLead) {
      const indexLead = this.filterSelected.leadsID.value.indexOf(idLead)
      if (indexLead === -1) {
        this.filterSelected.leadsID.value.push(idLead)
      } else {
        this.filterSelected.leadsID.value.splice(indexLead, 1)
      }
    },
    createNew(event, type) {
      if (type === 'lead') {
        const currentUser = this.$store.getters['auth/currentUser']
        if ((currentUser !== null) && (currentUser !== undefined)) {
          if ('extras' in currentUser) {
            if (currentUser.extras.basic === 1) {
              if (currentUser.id_role === 4) {
                if (this.tabSelectFilter !== '') {
                  this.$router.push(`/crm/leads/new-lead/${this.tabSelectFilter}`)
                } else {
                  this.$router.push('/crm/leads/new-lead')
                }
              } else {
                this.$router.push('/crm/leads/new-lead')
              }
            } else if (currentUser.extras.basic !== 1) {
              if ((this.tabSelectFilter === '') || (this.tabSelectFilter === 'minhas_leads')) {
                this.$router.push('/crm/leads/new-lead')
              } else { this.$router.push(`/crm/leads/new-lead/${this.tabSelectFilter}`) }
            }
          }
        }
      } else if (type === 'contact') {
        this.$router.push('/crm/leads/new-contact')
      }
    },
    async showMsgDetailRequest(pIcon, pDetail, resolve) {
      this.$swal({
        title: '',
        icon: pIcon || '',
        html: '<div id="modal-check-info" />',
        showCloseButton: false,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Continuar com a duplicação'),
        cancelButtonText: this.$t('Fechar'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
        willOpen: () => {
          const ComponentClass = Vue.extend(ModalCheckInfo)
          this.vueModalCheckInfoInstance = new ComponentClass({
            propsData: {
              itens: pDetail,
            },
          })
          this.vueModalCheckInfoInstance.$mount()
          document.getElementById('modal-check-info').appendChild(this.vueModalCheckInfoInstance.$el)
        },
      }).then(result => {
        this.vueModalCheckInfoInstance.$destroy()
        this.vueModalCheckInfoInstance = null

        if (result.value === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    },
    listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('crm_leads/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    formGestBookmarks() {
      this.$root.$emit('app::loading', true)
      this.showBookmarksModalEdition = true
    },
    formTabsManager() {
      this.$root.$emit('app::loading', true)
      this.showTabsManagerEdition = true
    },
    async showTabsListviewWithRecords() {
      await this.$store.dispatch('crm_leads/showTabsListviewWithRecords').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async fetchListView() {
      await this.$store.dispatch('crm_leads/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },

    // Popup das acoes do registo
    async openModalActionByRow(row) {
      if (checkNested(row, 'id') && (row.id !== '')) {
        this.$store.dispatch('crm_leads_events/previewModal', { sw025s01: row.id }).then(result => {
          this.showModalActionByRow = true
          this.rowModalAction = row
          this.extraModalAction = result
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    closeModalActionByRow() {
      this.showModalActionByRow = false
      this.rowModalAction = {}
      this.extraModalAction = {}
    },
    async openModalActionByMultipleRows() {
      this.showModalActionByMultipleRows = true
    },
    closeModalActionByMultipleRows() {
      this.showModalActionByMultipleRows = false
    },
    getNameTypeRegByTab(total) {
      if (this.tabSelectFilter !== '') {
        if (this.tabSelectFilter === 'contactos') {
          return this.$tc('Contacto', total)
        }
        if (this.tabSelectFilter === 'RGPD') {
          return `${this.$tc('Lead', total)} / ${this.$t('Contacto', total)}`
        }
        if (this.tabSelectFilter === 'lixo') {
          return `${this.$tc('Lead', total)} / ${this.$t('Contacto', total)}`
        }

        return this.$tc('Lead', total)
      }

      return this.$tc('Lead', total)
    },

    // Acoes do registo
    async removeSW025(sw025s01, pNameTypeReg, sw025s81) {
      let bShowAction = false
      let totalSelected = 0

      if (Array.isArray(sw025s01)) {
        if (sw025s01.length > 0) {
          bShowAction = true
          totalSelected = sw025s01.length
        }
      } else if (sw025s01 !== '') {
        bShowAction = true
        totalSelected = 1
      }

      let nameTypeReg = pNameTypeReg
      if (typeof nameTypeReg === 'undefined' || nameTypeReg === null || nameTypeReg === '') {
        nameTypeReg = this.getNameTypeRegByTab(totalSelected)
      }

      if (bShowAction === true) {
        let texConfirm = `${this.$tc('Pretende eliminar o {type}?', totalSelected, { type: nameTypeReg })}<br>${this.$t('Esta ação é irreversível!')}`

        this.$root.$emit('app::loading', true)
        await this.$store.dispatch('crm_leads_events/checkRemoveSW025', { sw025s01, type: nameTypeReg }).then(async res => {
          this.$root.$emit('app::loading', false)
          if (res.error === 1) {
            let messageAlertError = ''

            if (checkNested(res, 'msg') && (res.msg.length > 0)) {
              res.msg.forEach(el => {
                if (checkNested(el, 'name') && checkNested(el, 'msg')) {
                  messageAlertError += `<p><b>${el.name}</b><br />${el.msg}</p>`
                }
              })
            }

            if (messageAlertError !== '') {
              this.$swal({
                title: '',
                html: messageAlertError,
                icon: 'error',
                confirmButtonText: this.$t('Fechar'),
                customClass: {
                  confirmButton: 'btn btn-outline-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.showMsgErrorRequest({ message: this.$tc('Problema ao eliminar o {type}', totalSelected, { type: nameTypeReg }) })
            }
          } else {
            if ((Number(sw025s81) === 1) && (res.msg !== '')) {
              texConfirm += `<p class="mt-1 mb-0"><b>${this.$t('A eliminação deste Lead, vai eliminar todos os registos associados a este Lead, nomeadamente:')}</b></p>`
              texConfirm += `<p class="mb-0">${res.msg}</p>`
            }

            const actionModal = new Promise(resolve => {
              showModalDeleteMessage(resolve, texConfirm)
            })

            await actionModal.then(result => {
              if (result === 'confirm') {
                this.$root.$emit('app::loading', true)

                this.$store.dispatch('crm_leads_events/removeSW025', { sw025s01, type: nameTypeReg }).then(async resRemove => {
                  this.$root.$emit('app::loading', false)

                  if (resRemove.msg !== '') {
                    if (resRemove.error === 1) {
                      this.showMsgErrorRequest({ message: resRemove.msg })
                    } else {
                      this.showMsgSuccessRequest({ message: resRemove.msg })

                      this.closeModalActionByRow()

                      await this.fetchListView()
                    }
                  }
                }).catch(error => {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest(error)
                })
              }
            })
          }
        }).catch(error => {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(error)
        })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Escolha pelo menos um {type}', { type: this.getNameTypeRegByTab(0) }) })
      }
    },
    async restoreSW025(sw025s01, nameTypeReg) {
      if (sw025s01) {
        let texConfirm = `${this.$t('Pretende restaurar o Lead?')}`

        if (nameTypeReg !== '') {
          texConfirm = `${this.$t('Pretende restaurar o {type}?', { type: nameTypeReg })}`
        }

        this.$swal({
          title: '',
          html: texConfirm,
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)

            await this.$store.dispatch('crm_leads_events/restoreSW025', { sw025s01, type: nameTypeReg }).then(async resRemove => {
              this.$root.$emit('app::loading', false)

              if (resRemove.msg !== '') {
                if (resRemove.error === 1) {
                  this.showMsgErrorRequest({ message: resRemove.msg })
                } else {
                  this.showMsgSuccessRequest({ message: resRemove.msg })

                  this.closeModalActionByRow()

                  await this.fetchListView()
                }
              }
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async shareLead(sw025s01, nameLead) {
      if (sw025s01) {
        await this.$store.dispatch('crm_leads_events/chooseConsultorSendLead', { keyReg: sw025s01, txt: '' }).then(async agents => {
          await this.$refs.modalShareLead.showModal({
            agents,
            nameLead,
            keyReg: sw025s01,
          }).then(async resModal => {
            await this.$refs.modalShareLead.hideModal()
            if (resModal.share === true) {
              await this.showMsgSuccessRequest({ message: resModal.message })
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    async sendReference(sw025s01, nameLead) {
      if (sw025s01) {
        await this.$store.dispatch('crm_leads_events/chooseConsultorSendRef', { keyReg: sw025s01, txt: '' }).then(async res => {
          await this.$refs.modalShareReference.showModal({
            agents: res.agents,
            hubs: res.hubs,
            nameLead,
            keyReg: sw025s01,
          }).then(async resModal => {
            await this.$refs.modalShareReference.hideModal()
            if (resModal.share === true) {
              await this.showMsgSuccessRequest({ message: resModal.message })

              if (resModal.messageInfo !== '') {
                await this.showMsgSuccessRequest({ message: resModal.messageInfo })
              }
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    async shareLeadWithLeaderOrConsultor(sw025s01, nameLead) {
      let bShowAction = false
      let totalSelected = 0

      if (Array.isArray(sw025s01)) {
        if (sw025s01.length > 0) {
          bShowAction = true
          totalSelected = sw025s01.length
        }
      } else if (sw025s01 !== '') {
        bShowAction = true
        totalSelected = 1
      }

      const nameTypeReg = this.getNameTypeRegByTab(totalSelected)

      if (bShowAction === true) {
        await this.$store.dispatch('crm_leads_events/getNumberRspByLead', { keyReg: sw025s01 }).then(async res => {
          if (res.usersRspLead.length > 0) {
            await this.$refs.modalShareLeadWithLeaderOrConsultor.showModal({
              usersRspLead: res.usersRspLead,
              rspUnique: res.rspUnique,
              idsSelection: res.idsSelection,
              nameLead,
              keyReg: sw025s01,
              totalSelected,
              nameTypeReg,
            })
          } else {
            // Abrir o popup para adicionar novo responsavel
            await this.$refs.modalShareLeadWithLeaderOrConsultor.addUser({
              nameLead,
              keyReg: sw025s01,
              totalSelected,
              nameTypeReg,
            })
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Escolha pelo menos um {type}', { type: this.getNameTypeRegByTab(0) }) })
      }
    },
    async shareAddLeadWithLeaderOrConsultor(sw025s01, nameLead) {
      if (sw025s01) {
        this.$swal({
          title: '',
          html: this.$t('Deseja adicionar outro responsável?'),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await this.$refs.modalShareLeadWithLeaderOrConsultor.addUser({
              nameLead,
              keyReg: sw025s01,
              disableModalMain: true,
            })
          }
        })
      }
    },
    async changeTabLead(sw025s01, nameLead) {
      let bShowAction = false
      let totalSelected = 0

      if (Array.isArray(sw025s01)) {
        if (sw025s01.length > 0) {
          bShowAction = true
          totalSelected = sw025s01.length
        }
      } else if (sw025s01 !== '') {
        bShowAction = true
        totalSelected = 1
      }

      const nameTypeReg = this.getNameTypeRegByTab(totalSelected)

      if (bShowAction === true) {
        await this.$store.dispatch('crm_leads_events/changeTabUsersRspLeadByTeam', { sw025s01, type: nameTypeReg }).then(async res => {
          if (res.mode === 'users') {
            if (res.usersRspLead.length > 0) {
              await this.$refs.modalChangeTabsLeads.showModal({
                usersRspLead: res.usersRspLead,
                mode: res.mode,
                nameLead,
                keyReg: sw025s01,
                nameTypeReg,
                totalSelected,
              })
            } else {
              this.showMsgErrorRequest({ message: this.$tc('Nenhum responsável associado ao {type}', totalSelected, { type: nameTypeReg }) })
            }
          }

          if (res.mode === 'tabs') {
            if (res.tabs.length > 0) {
              await this.$refs.modalChangeTabsLeads.showModal({
                tabs: res.tabs,
                mode: res.mode,
                nameLead,
                keyReg: sw025s01,
                nameTypeReg,
                totalSelected,
              })
            } else {
              this.showMsgErrorRequest({ message: this.$t('Nenhum separador disponivel') })
            }
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Escolha pelo menos um {type}', { type: this.getNameTypeRegByTab(0) }) })
      }
    },
    async convertLeadToContacto(sw025s01, nameLead) {
      if (sw025s01) {
        this.$swal({
          title: '',
          html: this.$t('Pretende transformar o Lead {name} em contacto?', { name: `<b>${nameLead}</b>` }),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)
            this.$store.dispatch('crm_leads_events/convertLeadToContacto', { sw025s01 }).then(async msgSuccess => {
              this.$root.$emit('app::loading', false)
              if (msgSuccess) {
                this.showMsgSuccessRequest({ message: msgSuccess })
                this.closeModalActionByRow()
                await this.fetchListView()
              }
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async convertContactoToLead(sw025s01, nameLead) {
      if (sw025s01) {
        this.$swal({
          title: '',
          html: this.$t('Pretende transformar o contacto {name} em Lead?', { name: `<b>${nameLead}</b>` }),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)
            this.$store.dispatch('crm_leads_events/convertContactoToLead', { sw025s01 }).then(async msgSuccess => {
              this.$root.$emit('app::loading', false)
              if (msgSuccess) {
                this.showMsgSuccessRequest({ message: msgSuccess })
                await this.fetchListView()
              }
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async convertContactoToLeadLink(sw025s01) {
      if (sw025s01) {
        this.$router.push(`/crm/leads/contactToLead/new/${sw025s01}`)
      }
    },
    async convertContactoToLeadChooseTab(sw025s01, nameLead) {
      if (sw025s01) {
        this.$swal({
          title: '',
          html: this.$t('Pretende transformar o contacto {name} em Lead?', { name: `<b>${nameLead}</b>` }),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            await this.$store.dispatch('crm_leads_events/chooseTabLeads').then(async res => {
              await this.$refs.modalConvertContactToLeadChangeTabs.showModal({
                tabs: res.tabs,
                bookmarks: res.bookmarks,
                nameLead,
                keyReg: sw025s01,
              })
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async modalConsentimentoAction(sw025s01) {
      if (sw025s01) {
        await this.$store.dispatch('crm_leads_events/generateLinkConsentimento', { idLead: sw025s01 }).then(async res => {
          this.openModalActionRow = this.rowModalAction
          this.modalConsentimentoActionInfo = res
          this.modalConsentimentoActionTitle = this.$t('{name} Link de pedido de consentimento', { name: `<p class="mb-50"><b>${this.rowModalAction.name}</b></p>` })
          this.closeModalActionByRow()
          this.showModalLinkConsentimento = true
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      }
    },
    modalConsentimentoActionClose() {
      this.showModalLinkConsentimento = false

      if (checkNested(this.openModalActionRow, 'id') && (this.openModalActionRow !== '')) {
        this.openModalActionByRow(this.openModalActionRow)
      }

      this.openIDModalAction = null
    },
    async saveLeadShareToUser(sw025s01, nameLead) {
      if (sw025s01) {
        this.$swal({
          title: '',
          html: this.$t('Deseja guardar esta Lead ({name})?', { name: `<b>${nameLead}</b>` }),
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)
            await this.$store.dispatch('crm_leads_events/checkExistAllMyLeads', {
              sw025s01,
              swShare: 1,
            }).then(async res => {
              if (res.error === 1) {
                if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
                  this.$root.$emit('app::loading', false)

                  const actionModal = new Promise(resolve => {
                    this.showMsgDetailRequest('warning', res.alerts, resolve)
                  })

                  await actionModal.then(resActionModal => {
                    if (resActionModal === false) {
                      throw new Error('')
                    }
                  })
                }
              }

              // Verifica se uma utilizador basico
              const oCurrentUser = this.$store.getters['auth/currentUser']

              if (checkNested(oCurrentUser, 'id_role') && (oCurrentUser.id_role === 4) && checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
                this.$root.$emit('app::loading', true)
                await this.$store.dispatch('crm_leads_events/chooseTabLeads').then(async resData => {
                  this.$root.$emit('app::loading', false)

                  const actionModalChooseTab = new Promise(resolve => {
                    this.$refs.modalConvertContactToLeadChangeTabs.showModal({
                      tabs: resData.tabs,
                      bookmarks: resData.bookmarks,
                      nameLead,
                      keyReg: sw025s01,
                      saveShare: true,
                      resolve,
                    })
                  })

                  await actionModalChooseTab
                }).catch(error => {
                  throw error
                })
              }

              this.$root.$emit('app::loading', true)
              await this.$store.dispatch('crm_leads_events/saveLeadShareToUser', { sw025s01 }).then(async msgSuccess => {
                this.$root.$emit('app::loading', false)
                if (msgSuccess) {
                  this.showMsgSuccessRequest({ message: msgSuccess })
                  this.closeModalActionByRow()
                  await this.fetchListView()
                }
              }).catch(error => {
                throw error
              })
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async saveLeadRefToUser(sw025s01, nameLead, accept) {
      if (sw025s01) {
        if (accept === true) {
          this.$swal({
            title: '',
            html: this.$t('Deseja aceitar esta referência ({name})?', { name: `<b>${nameLead}</b>` }),
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              this.$root.$emit('app::loading', true)
              await this.$store.dispatch('crm_leads_events/checkExistAllMyLeads', {
                sw025s01,
                swShare: 1,
              }).then(async res => {
                if (res.error === 1) {
                  if (Array.isArray(res.alerts) && (res.alerts.length > 0)) {
                    this.$root.$emit('app::loading', false)

                    const actionModal = new Promise(resolve => {
                      this.showMsgDetailRequest('warning', res.alerts, resolve)
                    })

                    await actionModal.then(resActionModal => {
                      if (resActionModal === false) {
                        throw new Error('')
                      }
                    })
                  }
                }

                // Verifica se uma utilizador basico
                const oCurrentUser = this.$store.getters['auth/currentUser']

                if (checkNested(oCurrentUser, 'id_role') && (oCurrentUser.id_role === 4) && checkNested(oCurrentUser, 'extras.basic') && (oCurrentUser.extras.basic === 1)) {
                  this.$root.$emit('app::loading', true)
                  await this.$store.dispatch('crm_leads_events/chooseTabLeads').then(async resData => {
                    this.$root.$emit('app::loading', false)

                    const actionModalChooseTab = new Promise(resolve => {
                      this.$refs.modalConvertContactToLeadChangeTabs.showModal({
                        tabs: resData.tabs,
                        bookmarks: resData.bookmarks,
                        nameLead,
                        keyReg: sw025s01,
                        saveShare: true,
                        resolve,
                      })
                    })

                    await actionModalChooseTab
                  }).catch(error => {
                    throw error
                  })
                }

                this.$root.$emit('app::loading', true)
                await this.$store.dispatch('crm_leads_events/saveLeadRefToUser', { sw025s01 }).then(async msgSuccess => {
                  this.$root.$emit('app::loading', false)
                  if (msgSuccess) {
                    this.showMsgSuccessRequest({ message: msgSuccess })
                    this.closeModalActionByRow()
                    await this.fetchListView()
                  }
                }).catch(error => {
                  throw error
                })
              }).catch(error => {
                this.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              })
            }
          })
        } else {
          this.$swal({
            title: '',
            html: this.$t('Tem a certeza que pretende rejeitar esta referência ({name})?', { name: `<b>${nameLead}</b>` }),
            icon: 'question',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: this.$t('Sim'),
            cancelButtonText: this.$t('Não'),
            customClass: {
              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              this.$root.$emit('app::loading', true)
              await this.$store.dispatch('crm_leads_events/deleteRef', { sw025s01 }).then(async msgSuccess => {
                this.$root.$emit('app::loading', false)
                if (msgSuccess) {
                  this.showMsgSuccessRequest({ message: msgSuccess })
                  this.closeModalActionByRow()
                  await this.fetchListView()
                }
              }).catch(error => {
                this.showMsgErrorRequest(error)
              })
            }
          })
        }
      }
    },
    async addBookmarksLeads(sw025s01, pNameTypeReg) {
      let bShowAction = false
      let totalSelected = 0

      if (Array.isArray(sw025s01)) {
        if (sw025s01.length > 0) {
          bShowAction = true
          totalSelected = sw025s01.length
        }
      } else if (sw025s01 !== '') {
        bShowAction = true
        totalSelected = 1
      }

      let nameTypeReg = pNameTypeReg
      if (typeof nameTypeReg === 'undefined' || nameTypeReg === null || nameTypeReg === '') {
        nameTypeReg = this.getNameTypeRegByTab(totalSelected)
      }

      if (bShowAction === true) {
        await this.$store.dispatch('crm_leads_events/modalAddBookmarks', { sw025s01 }).then(async res => {
          if (checkNested(res, 'bookmarksUser') && (res.bookmarksUser.length > 0)) {
            await this.$refs.modalAddBookmarks.showModal({
              sw025s01,
              totalSelected,
              nameTypeReg,
              bookmarks: res.bookmarksUser,
            })
          } else {
            this.showMsgErrorRequest({ message: this.$t('Não existe nenhum marcador para associar') })
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Escolha pelo menos um {type}', { type: this.getNameTypeRegByTab(0) }) })
      }
    },
    listViewSavePositionScroll() {
      this.sPostionListview = {
        scrollTop: this.$refs.listviewScroll.$el.scrollTop,
        scrollLeft: this.$refs.listviewScroll.$el.scrollLeft,
      }
    },
    async loadFilterCustomQuery(paramsQuery) {
      return new Promise(resolve => {
        let filterCustomQuery = false
        if ((paramsQuery !== null) && (paramsQuery !== undefined)) {
        // Ativa o tabulador "Leads partilhados ou referenciados"
          if ('leads-partilhados' in paramsQuery) {
            this.$store.dispatch('crm_leads/setInitialTabsDefault', { setMode: 'tabLeads' })
            this.$store.dispatch('crm_leads/showItensLeadsRefShares')
            this.$store.commit('crm_leads/setFilterSelected', { filterSelectedPartilhados: 'all' })
            filterCustomQuery = true
          }

          if ('leads-referenciados' in paramsQuery) {
            this.$store.dispatch('crm_leads/setInitialTabsDefault', { setMode: 'tabLeads' })
            this.$store.dispatch('crm_leads/showItensLeadsRefShares')
            this.$store.commit('crm_leads/setFilterSelected', { filterSelectedReferenciados: 'all' })
            filterCustomQuery = true
          }

          if ('rgpd' in paramsQuery) {
            this.$store.dispatch('crm_leads/setInitialTabsDefault', { setMode: 'tabRGPD' })
            filterCustomQuery = true
          }

          if ('leads' in paramsQuery) {
            this.$store.dispatch('crm_leads/setInitialTabsDefault', { setMode: 'tabLeads' })
            filterCustomQuery = true
          }

          if ('contactos' in paramsQuery) {
            this.$store.dispatch('crm_leads/setInitialTabsDefault', { setMode: 'tabContactos' })
            filterCustomQuery = true
          }
        }

        if (filterCustomQuery === false) {
          this.$store.commit('crm_leads/initStates')
        } else {
          this.$router.replace({ query: {} })
        }

        resolve({ filterCustomQuery })
      })
    },
  },
  setup() {
    const CRM_LEADS_MODULE_NAME_LIST = 'crm_leads'
    const CRM_LEADS_MODULE_NAME_EVENTS = 'crm_leads_events'

    if (!store.hasModule(CRM_LEADS_MODULE_NAME_LIST)) {
      store.registerModule(CRM_LEADS_MODULE_NAME_LIST, crmLeadsListModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_LEADS_MODULE_NAME_LIST)) store.unregisterModule(CRM_LEADS_MODULE_NAME_LIST)
      })
    }

    if (!store.hasModule(CRM_LEADS_MODULE_NAME_EVENTS)) {
      store.registerModule(CRM_LEADS_MODULE_NAME_EVENTS, crmLeadsEventsModule)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const sPostionListview = {
      scrollTop: 0,
      scrollLeft: 0,
    }

    const { route } = useRouter()
    const routeParamsQuery = computed(() => route.value.query)

    return {
      mqShallShowLeftSidebar,
      sPostionListview,
      routeParamsQuery,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

.pull-down-container ::v-deep{
  .pull-down-header{
    background-color:inherit!important;
  }
  .pull-down-content{
    font-family:inherit!important;
    color:inherit!important;
    font-size:inherit!important;
  }
}
</style>
