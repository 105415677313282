<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          :class="{'email-app-menu': true, 'sws-not-button-create': notShowButtonCreate && (currentBreakPointMobile === false) }"
        >
          <div
            v-if="(permsUser.canCreateLeads === true) && (filterData.showCreateLeads.value === true) && (currentBreakPointMobile === false)"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              :disabled="$store.getters['crm_leads/loadingListview']"
              @click="createNew($event, 'lead');$emit('close-left-sidebar');"
            >
              {{ $t('Novo Lead') }}
            </b-button>
          </div>

          <div
            v-if="(permsUser.canCreateContacto === true) && (filterData.showCreateContacto.value === true) && (currentBreakPointMobile === false)"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              :disabled="$store.getters['crm_leads/loadingListview']"
              @click="createNew($event, 'contact');$emit('close-left-sidebar');"
            >
              {{ $t('Novo contacto') }}
            </b-button>
          </div>

          <div
            v-if="currentBreakPointMobile===true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              @click="$emit('close-left-sidebar');"
            >
              {{ $t('Pesquisar') }}
            </b-button>
          </div>

          <vue-perfect-scrollbar
            v-if="filterData.load.value===true"
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <!-- Leads partilhados / referenciados / todos -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-if="((filterData.totalLeadsReferenciados.value > 0) || (filterData.totalLeadsRecebidas.value > 0)) && (filterData.showItensLeadsRefShares.value === true)"
                :style="{cursor: 'pointer' }"
                :active="(((filterSelected.referenciados.value !== 'all') && (filterSelected.partilhados.value !== 'all')) ? true : false)"
                @click="listviewFilterTabField('leads_all')"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos os Leads') }}</span>
              </b-list-group-item>
              <b-list-group-item
                v-if="(filterData.totalLeadsRecebidas.value > 0) && (filterData.showItensLeadsRefShares.value === true)"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.partilhados.value === 'all') ? true : false)"
                :class="((filterData.totalLeadsRecebidasNew.value > 0) ? 'text-danger' : '')"
                @click="listviewFilterTabField('leads_partilhadas')"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Leads partilhados') }}</span>
                <!--
                <b-badge
                  v-if="filterData.totalLeadsRecebidasNew.value > 0"
                  pill
                  :variant="((filterData.totalLeadsRecebidasNew.value > 0) ? 'light-danger' : 'light-primary')"
                  class="float-right"
                >
                  {{ $t('Novos') }}
                </b-badge>
                -->
              </b-list-group-item>
              <b-list-group-item
                v-if="(filterData.totalLeadsReferenciados.value > 0) && (filterData.showItensLeadsRefShares.value === true)"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.referenciados.value === 'all') ? true : false)"
                :class="((filterData.totalLeadsReferenciadosNew.value > 0) ? 'text-danger' : '')"
                @click="listviewFilterTabField('leads_referenciados')"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Leads referenciados') }}</span>
                <!--
                <b-badge
                  v-if="filterData.totalLeadsReferenciadosNew.value > 0"
                  pill
                  :variant="((filterData.totalLeadsReferenciadosNew.value > 0) ? 'light-danger' : 'light-primary')"
                  class="float-right"
                >
                  {{ $t('Novos') }}
                </b-badge>
                -->
              </b-list-group-item>
            </b-list-group>

            <!-- Separadores -->
            <div
              v-if="filterData.tabsListview.value.length > 0"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Separador') }}
              </h6>
              <div
                v-if="(filterData.actionImportLeads.value === true) || (filterData.actionEditTabsTxt.value === true)"
                class="dropdown"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0 mr-1"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      :size="sizeIconsAction"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="filterData.actionImportLeads.value === true"
                    disabled
                    @click.stop.prevent="$emit('close-left-sidebar')"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      :size="sizeIconsAction"
                    />
                    {{ $t('Importar') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="filterData.actionEditTabsTxt.value === true"
                    @click.stop.prevent="formTabsManager();$emit('close-left-sidebar')"
                  >
                    <feather-icon
                      icon="SidebarIcon"
                      :size="sizeIconsAction"
                    />
                    {{ $t('Editar separadores') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <b-list-group
              v-if="filterData.tabsListview.value.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.tabsListview.value.filter((oTab) => oTab.show === true)"
                :key="oTab.key"
                :style="{cursor: 'pointer' }"
                :class="{'text-danger': ((oTab.key === 'RGPD' || oTab.key === 'portais_imobiliarios') ? true : false)}"
                :active="((tabSelectFilter === oTab.key) ? true : false)"
                @click.stop.prevent="listviewFilterTab(oTab.key)"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Responsável (so equipa) -->
            <h6
              v-if="(filterData.allUsersAssocLeader.value.length > 0) && (filterData.showResponsableUser.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Responsável') }}
            </h6>
            <b-list-group
              v-if="(filterData.allUsersAssocLeader.value.length > 0) && (filterData.showResponsableUser.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item>
                <v-select
                  v-model="filterSelected.responsableUser.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="filterData.allUsersAssocLeader.value"
                  label="nome"
                  item-text="nome"
                  item-value="id"
                  class="limitHeightUserResponsable"
                  @input="listviewFilterUserResponsable(filterSelected.responsableUser.value)"
                >
                  <template #option="{ nome }">
                    <span> {{ nome }}</span>
                  </template>
                  <div slot="no-options">
                    {{ $t('Nenhum resultado') }}
                  </div>
                </v-select>
              </b-list-group-item>
            </b-list-group>

            <!-- Tipo de cliente :: Contacto -->
            <h6
              v-if="(filterData.tiposClienteContacto.value.length > 0) && (filterData.showTiposClienteContacto.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Tipo de cliente') }}
            </h6>
            <b-list-group
              v-if="(filterData.tiposClienteContacto.value.length > 0) && (filterData.showTiposClienteContacto.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.tiposClienteContacto.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedTiposClienteContacto': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.tiposClienteContacto.value"
                :key="row.sw022s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.tiposClienteContacto.value.includes(row.sw022s01)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedTiposClienteContacto': row.sw022s01 })"
              >
                <span class="align-text-bottom line-height-1">{{ row.sw022s02 }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tipo de cliente :: Leads -->
            <h6
              v-if="(filterData.tiposClienteLead.value.length > 0) && (filterData.showTiposClienteLead.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Tipo de cliente') }}
            </h6>
            <b-list-group
              v-if="(filterData.tiposClienteLead.value.length > 0) && (filterData.showTiposClienteLead.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.tiposClienteLead.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedTiposClienteLead': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.tiposClienteLead.value"
                :key="row.sw022s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.tiposClienteLead.value.includes(row.sw022s01)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedTiposClienteLead': row.sw022s01 })"
              >
                <span class="align-text-bottom line-height-1">{{ row.sw022s02 }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tipo de cliente (Estado comprador) -->
            <h6
              v-if="(filterData.estadosLeadComprador.value.length > 0) && (filterSelected.showStatusComprador.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Estado comprador') }}
            </h6>
            <b-list-group
              v-if="(filterData.estadosLeadComprador.value.length > 0) && (filterSelected.showStatusComprador.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadosLeadComprador.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadosLeadComprador': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.estadosLeadComprador.value"
                :key="row.sw022s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadosLeadComprador.value.includes(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadosLeadComprador': row.id })"
              >
                <span class="align-text-bottom line-height-1">{{ row.designacao }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tipo de cliente (Estado vendedor) -->
            <h6
              v-if="(filterData.estadosLeadVendedor.value.length > 0) && (filterSelected.showStatusVendedor.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Estado vendedor') }}
            </h6>
            <b-list-group
              v-if="(filterData.estadosLeadVendedor.value.length > 0) && (filterSelected.showStatusVendedor.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadosLeadVendedor.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadosLeadVendedor': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.estadosLeadVendedor.value"
                :key="row.sw022s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadosLeadVendedor.value.includes(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadosLeadVendedor': row.id })"
              >
                <span class="align-text-bottom line-height-1">{{ row.designacao }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Marcadores -->
            <div
              v-if="(filterData.showBookmarksByUser.value === true)"
              class="mt-3 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Marcadores') }}
              </h6>
              <feather-icon
                v-if="(filterData.bookmarksByUser.value.length > 0) && (filterData.actionEditBookmarksTxt.value === true)"
                icon="EditIcon"
                class="cursor-pointer"
                :size="sizeIconsAction"
                @click.stop.prevent="formGestBookmarks();$emit('close-left-sidebar')"
              />
            </div>
            <b-list-group
              v-if="(filterData.bookmarksByUser.value.length > 0) && (filterData.showBookmarksByUser.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.bookmarksByUser.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedBookmarksByUser': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="bookmark in filterData.bookmarksByUser.value"
                :key="bookmark.sw075s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.bookmarksByUser.value.includes(encodeID(bookmark.sw075s01))) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedBookmarksByUser': encodeID(bookmark.sw075s01) })"
              >
                <feather-icon
                  icon="BookmarkIcon"
                  :size="sizeIconsAction"
                  class="mr-75"
                  :style="{color: bookmark.sw075s04}"
                />
                <span class="align-text-bottom line-height-1">{{ bookmark.sw075s03 }}</span>
              </b-list-group-item>

              <b-list-group-item>
                <b-form-checkbox
                  v-model="filterSelected.bookmarksCombinationByUser.value"
                  :disabled="((filterSelected.bookmarksByUser.value.length >= 2) ? false : true)"
                  @change="fetchListView"
                >
                  {{ $t('Combinação') }}
                </b-form-checkbox>
              </b-list-group-item>

            </b-list-group>
            <div
              v-if="(filterData.bookmarksByUser.value.length === 0) && (filterData.showBookmarksByUser.value === true)"
              class="form-group-compose text-center compose-btn pt-0 pb-0"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                @click="formGestBookmarks();$emit('close-left-sidebar')"
              >
                {{ $t('Gerir marcadores') }}
              </b-button>
            </div>

            <!-- Classificação -->
            <h6
              v-if="(filterData.classificacaoLead.value.length > 0) && (filterData.showClassificacaoLead.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Classificação') }}
            </h6>
            <b-list-group
              v-if="(filterData.classificacaoLead.value.length > 0) && (filterData.showClassificacaoLead.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.classificacaoLead.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedClassificacaoLead': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.classificacaoLead.value"
                :key="row.sw021s01"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.classificacaoLead.value.includes(row.sw021s01)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedClassificacaoLead': row.sw021s01 })"
              >
                <span class="align-text-bottom line-height-1">{{ row.sw021s03 }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- RGPD - Situacoes Pendentes -->
            <h6
              v-if="(filterData.rgpdSituacoesPendentes.value.length > 0) && (filterData.showRGPDSituacoesPendentes.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('RGPD') }} - {{ $t('Situações pendentes') }}
            </h6>
            <b-list-group
              v-if="(filterData.rgpdSituacoesPendentes.value.length > 0) && (filterData.showRGPDSituacoesPendentes.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.rgpdSituacoesPendentes.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedRGPDSituacoesPendentes': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.rgpdSituacoesPendentes.value"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.rgpdSituacoesPendentes.value.includes(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedRGPDSituacoesPendentes': row.id })"
              >
                <span class="align-text-bottom line-height-1">{{ row.txt }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Marketing (consentimento) -->
            <h6
              v-if="(filterData.consentimento.value.length > 0) && (filterData.showConsentimento.value === true)"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Marketing') }}
            </h6>
            <b-list-group
              v-if="(filterData.consentimento.value.length > 0) && (filterData.showConsentimento.value === true)"
              class="list-group-messages"
            >
              <b-list-group-item
                key="all"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.consentimento.value.includes('all')) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedConsentimento': 'all' })"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Todos') }}</span>
              </b-list-group-item>

              <b-list-group-item
                v-for="row in filterData.consentimento.value"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.consentimento.value.includes(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedConsentimento': row.id })"
              >
                <span class="align-text-bottom line-height-1">{{ row.txt }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Opções adicionais à pesquisa -->
            <h6
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Opções adicionais à pesquisa') }}
            </h6>
            <b-list-group
              class="list-group-messages"
            >
              <b-list-group-item
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.searchLevel.includes('familia')) ? true : false)"
                @click.stop.prevent="listviewFilterSearchLevel('familia')"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Incluir familiares') }}</span>
              </b-list-group-item>
              <b-list-group-item
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.searchLevel.includes('contactosadicionais')) ? true : false)"
                @click.stop.prevent="listviewFilterSearchLevel('contactosadicionais')"
              >
                <span class="align-text-bottom line-height-1">{{ $t('Incluir contactos adicionais') }}</span>
              </b-list-group-item>

            </b-list-group>

            <!-- Botao limpar -->
            <div
              v-if="((filterSelected.totalFields.value > 0) ? true : false)"
              class="form-group-compose text-center compose-btn"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                block
                :disabled="$store.getters['crm_leads/loadingListview']"
                @click="resetListviewFilterGeral()"
              >
                {{ $t('Limpar filtro') }}
              </b-button>
            </div>

            <!-- Extra space -->
            <h6
              class="section-label mt-1 mb-1 px-2"
            >
              {{ '\xa0' }}
            </h6>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BButton, BListGroup, BListGroupItem, BFormCheckbox, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import btoa from 'btoa'
import { configSettingsScroll, currentBreakPointMobile } from '@core-custom/utils/ui'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    VuePerfectScrollbar,
    vSelect,
  },
  mixins: [sizeIconsAction],
  props: {
    createNew: {
      type: Function,
      required: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    formGestBookmarks: {
      type: Function,
      required: true,
    },
    formTabsManager: {
      type: Function,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_leads', ['filterData', 'filterSelected', 'tabSelectFilter', 'permsUser']),
    notShowButtonCreate() {
      let showButton = false

      if ((this.permsUser.canCreateLeads === true) && (this.filterData.showCreateLeads.value === true)) {
        showButton = true
      }

      if ((this.permsUser.canCreateContacto === true) && (this.filterData.showCreateContacto.value === true)) {
        showButton = true
      }

      return (showButton !== true)
    },
  },
  methods: {
    encodeID(id) {
      return btoa(id)
    },
    fetchListView() {
      this.$store.dispatch('crm_leads/fetchListView').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterTab(tab) {
      this.$store.dispatch('crm_leads/listviewFilterTab', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterTabField(tab) {
      this.$store.dispatch('crm_leads/listviewFilterTabField', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterGeral(params) {
      this.$store.dispatch('crm_leads/listviewFilterGeral', params).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterUserResponsable(oResponsableSelected) {
      this.$store.dispatch('crm_leads/listviewFilterUserResponsable', oResponsableSelected).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilterGeral() {
      this.$store.dispatch('crm_leads/resetListviewFilterGeral').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterSearchLevel(params) {
      this.$store.dispatch('crm_leads/listviewFilterSearchLevel', params).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    return {
      configSettingsScroll,
      currentBreakPointMobile,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%);
}

.limitHeightUserResponsable ::v-deep {
  .vs__dropdown-menu{
    max-height: 175px!important;
  }
}
</style>
