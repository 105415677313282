import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'

const defaultState = () => ({
  // Variaveis do filtro com os dados selecionados
  filterSelectedTiposClienteLead: ref(['all']),
  filterSelectedTiposClienteContacto: ref(['all']),
  filterSelectedBookmarksByUser: ref(['all']),
  filterSelectedClassificacaoLead: ref(['all']),
  filterSelectedEstadosLeadComprador: ref(['all']),
  filterSelectedEstadosLeadVendedor: ref(['all']),
  filterSelectedConsentimento: ref(['all']),
  filterSelectedResponsableUser: ref({ nome: 'Todos', id: '' }),
  filterSelectedShowStatusComprador: ref(false),
  filterSelectedShowStatusVendedor: ref(false),
  filterSelectedLeadsID: ref([]),
  filterSelectedBookmarksCombinationByUser: ref(false),
  filterSelectedRGPDSituacoesPendentes: ref(['all']),
  filterSelectedPartilhados: ref(''),
  filterSelectedReferenciados: ref(''),
  filterSelectedTotalFields: ref(0),
  filterSelectedSearchLevel: ref([]),

  // Variaveis do filtro com os dados
  filterDataTiposClienteLead: ref([]),
  filterDataTiposClienteContacto: ref([]),
  filterDataBookmarksByUser: ref([]),
  filterDataClassificacaoLead: ref([]),
  filterDataEstadosLeadComprador: ref([]),
  filterDataEstadosLeadVendedor: ref([]),
  filterDataAllUsersAssocLeader: ref([]),
  filterDataConsentimento: ref([]),
  filterDataTotalLeadsRGPD: ref(0),
  filterDataTotalLeadsRecebidas: ref(0),
  filterDataTotalLeadsRecebidasNew: ref(0),
  filterDataTotalLeadsReferenciados: ref(0),
  filterDataTotalLeadsReferenciadosNew: ref(0),
  filterDataTotalImportados: ref(0),
  filterDataTotalImportadosFilesNoProcess: ref(0),
  filterDataTabsListview: ref([]),
  filterDataShowItensLeadsRefShares: ref(false),
  filterDataActionEditBookmarksTxt: ref(false),
  filterDataActionEditTabsTxt: ref(false),
  filterDataActionImportLeads: ref(false),
  filterDataRGPDSituacoesPendentes: ref([]),
  filterDataShowResponsableUser: ref(false),
  filterDataShowTiposClienteLead: ref(false),
  filterDataShowTiposClienteContacto: ref(false),
  filterDataShowBookmarksByUser: ref(false),
  filterDataShowClassificacaoLead: ref(false),
  filterDataShowConsentimento: ref(false),
  filterDataShowRGPDSituacoesPendentes: ref(false),
  filterDataShowCreateLeads: ref(false),
  filterDataShowCreateContacto: ref(false),
  filterDataLoad: ref(false),
  filterDataTotalLeadsPortais: ref(0),

  // Variaveis gerais da listagem
  listviewInit: ref(1),
  listviewData: ref([]),
  positionListview: {},

  tabSelectFilter: ref(''),
  loadingListview: ref(true),
  perPage: ref(25),
  totalRows: ref(0),
  currentPage: ref(1),
  perPageOptions: ref([10, 25, 50, 100]),
  searchTxt: ref(''),
  listviewOrderBy: ref({
    column: 'lastChange',
    sort: 'desc',
  }),
  aTabsHiddenStart: ref(['leads_c1', 'leads_c2', 'leads_c3', 'leads_c4', 'leads_c5', 'leads_v1', 'leads_v2', 'leads_v3', 'leads_v4', 'leads_v5']),
  listview_txt_default: i18n.t('Nenhum resultado disponível'),
  listiview_txt_not_find: i18n.t('Não encontrou nenhum resultado'),
  listiview_txt_search: i18n.t('Nome, telemóvel, email, nickname ou código'),
  listview_txt_loading: i18n.t('A carregar registos ...'),
  canCreateContacto: ref(false),
  canCreateLeads: ref(false),
  aFieldsOrder: [
    {
      name: 'lastChange',
      txt: i18n.t('Ordenar pela última alteração'),
    },
    {
      name: 'name',
      txt: i18n.t('Ordenar por nome'),
    },
    {
      name: 'phone',
      txt: i18n.t('Ordenar por telemóvel'),
    },
    {
      name: 'email',
      txt: i18n.t('Ordenar por email'),
    },
    {
      name: 'angariado',
      txt: i18n.t('Ordenar por angariado'),
    },
    {
      name: 'inatividade',
      txt: i18n.t('Ordenar por inatividade'),
    },
  ],

  // States para guardar em localstorage
  historyKeyStorage: '',
  statesToLocalStorage: [
    'filterSelectedTiposClienteLead',
    'filterSelectedTiposClienteContacto',
    'filterSelectedBookmarksByUser',
    'filterSelectedClassificacaoLead',
    'filterSelectedEstadosLeadComprador',
    'filterSelectedEstadosLeadVendedor',
    'filterSelectedConsentimento',
    'filterSelectedResponsableUser',
    'filterSelectedShowStatusComprador',
    'filterSelectedShowStatusVendedor',
    'filterSelectedBookmarksCombinationByUser',
    'filterSelectedLeadsID',
    'filterSelectedTotalFields',
    'filterSelectedRGPDSituacoesPendentes',
    'filterSelectedPartilhados',
    'filterSelectedReferenciados',
    'filterSelectedSearchLevel',

    'filterDataShowItensLeadsRefShares',
    'filterDataShowResponsableUser',
    'filterDataShowTiposClienteLead',
    'filterDataShowTiposClienteContacto',
    'filterDataShowBookmarksByUser',
    'filterDataShowClassificacaoLead',
    'filterDataShowConsentimento',
    'filterDataShowRGPDSituacoesPendentes',
    'filterDataShowCreateLeads',
    'filterDataShowCreateContacto',

    'tabSelectFilter',
    'currentPage',
    'searchTxt',
    'listviewOrderBy',
    'positionListview',
  ],

})

export default defaultState
